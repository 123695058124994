import { useState, useEffect } from "react";
import ModalDialog from "./ModalDialog";
import Input from "components/UserInput/Input";
import PropTypes from "prop-types";

function ConfirmDeleteDialog({ open, onDelete, onCancel }) {
  const DELETE_KEYWORD = "DELETE";

  const [confirmationBoxValue, setConfirmationBoxValue] = useState("");
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

  useEffect(() => {
    setIsDeleteDisabled(confirmationBoxValue.trim().toUpperCase() !== DELETE_KEYWORD);
  }, [confirmationBoxValue]);

  function onDeleteWrapper() {
    if (!isDeleteDisabled) {
      onDelete();

      setConfirmationBoxValue("");
    }
  }

  function onCancelWrapper() {
    onCancel();

    setConfirmationBoxValue("");
  }

  return (
    <ModalDialog
      title="Confirm deletion"
      open={open}
      positiveButtonText="Delete"
      onPositiveButton={onDeleteWrapper}
      positiveButtonDisabled={isDeleteDisabled}
      negativeButtonText="Cancel"
      onNegativeButton={onCancelWrapper}>
      <div className="flex flex-col gap-2">
        Type {DELETE_KEYWORD} in the box below to confirm deletion.

        <Input value={confirmationBoxValue} onChange={value => setConfirmationBoxValue(value)} />
      </div>
    </ModalDialog>
  );
}

ConfirmDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ConfirmDeleteDialog;