import React, { Component } from "react";
import { MutatingDots } from  'react-loader-spinner'
import "style/Loader.css";

class PageLoader extends Component {
  render() {
    return (
      <div className={"Loader " + (this.props.loading ? "block" : "hidden")}>
        <MutatingDots
          height="100"
          width="100"
          color={(localStorage.getItem("user")) ? localStorage.getItem("user").color : "#0D2539"}
          secondaryColor="#0D2539"
          ariaLabel='Loading'
        />
      </div>
    );
  }
}

export default PageLoader;
