import { Pie } from "@nivo/pie";
import RefreshButton from "components/Buttons/RefreshButton";

function PieChart({ title, data, onSliceClick, onResetClick, showReset }) { 
  function renderTooltip({datum: {id, data, value}}) {
    return (
      <div className="flex gap-1 p-2 bg-white rounded-lg shadow-xl items-center">
        <div style={{background: `${data.tooltipColor}`, width: "1rem", height: "1rem"}}/>
          {id === "N/A" ? id : `${data.lss} (${id})`} -
          <span className="font-bold">{value}%</span>
      </div>
    );
  }

  function changeCursor(_, event) {
    event.target.style.cursor = "pointer";
  }

  return (
    <div className="flex flex-col h-full">
      <div className="grid grid-cols-3">
        <div></div>
        <h1 className="text-lg text-center">{title}</h1>
        {showReset &&
          <RefreshButton className="flex justify-center w-6 px-[14px] py-[6px] rounded-full" onClick={onResetClick}/>
        }
      </div>
      <Pie
        onMouseEnter={changeCursor}
        onClick={onSliceClick}
        width={600}
        height={400}
        data={data}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        innerRadius={0}
        padAngle={2}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: "data.color" }}
        label
        borderColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              0.2
            ]
          ]
        }}
        tooltip={renderTooltip}
        arcLabel={d => `${d.value}%`}
        arcLinkLabelsSkipAngle={0}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={25}
        arcLabelsTextColor="#FFFFFF"
      />
    </div>
  );
}

export default PieChart;