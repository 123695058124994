import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Button from "components/Buttons/Button";
import Input from "components/UserInput/Input";
import FormRow from "components/Superuser/FormRow";
import { faRotateRight, faCopy } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { generatePassword } from "Utils";
import CopyButton from "components/Buttons/CopyButton";
import RefreshButton from "components/Buttons/RefreshButton";
import Checkbox from "components/UserInput/Checkbox";

library.add(faRotateRight, faCopy);

/**
 * @param {{
 *  defaultValues: {},
 *  submitButtonText: string,
 *  onSubmit: (data, event, resetForm: () => {}, setError: () => {}) => void,
 *  createMode: boolean
 * }} props
 */
export default function CUForm({ defaultValues, submitButtonText, onSubmit, createMode }) {
  const { register, formState: { errors }, reset, setError, setValue, handleSubmit } = useForm({
    defaultValues: defaultValues ?? {
      username: "",
      password: "",
      name: "",
      shortname: "",
      doximKey: "",
      doximSecret: "",
      customColor: "",
      customLink: "",
      customBrand: "",
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(""); // Password needs to be stored in state so the copy button can access it

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function generatePasswordHandler() {
    const newPassword = generatePassword(10);

    setPassword(newPassword);
    setValue("password", newPassword);
  }

  function resetForm() {
    setPassword("");
    reset();
  }

  return (
    <form className="flex flex-col gap-2 mx-auto w-11/12" onSubmit={handleSubmit((data, event) => onSubmit(data, event, resetForm, setError))}>
      {createMode &&
        <FormRow>
          <Input 
            labelText="Username"
            form={{
              register, 
              name: "username", 
              error: errors.username, 
              options: { required: "Username is required" }}}/>
          <div className="flex flex-col flex-1 gap-2">
            <Input 
              labelText="Password" 
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
              value={password}
              onChange={value => setPassword(value)}
              form={{
                register,
                name: "password",
                error: errors.password,
                options: { required: "Password is required" }}}
              buttonComponent={
                <>
                  <CopyButton value={password} onCopy={() => toast.success("Password copied to clipboard")} aria-label="copy-password-button"/>
                  <RefreshButton onClick={generatePasswordHandler} aria-label="refresh-password-button"/>
                </>
              }/>
              <Checkbox label="Show password" onChange={() => setShowPassword(!showPassword)}/>
            </div>
        </FormRow>
      }

      <FormRow>
        <Input 
          labelText="Name"
          form={{
            register, 
            name: "name", 
            error: errors?.name, 
            options: { required: "Name is required" }}}/>
        <Input
          labelText="Short name"
          form={{
            register,
            name: "shortname",
            error: errors.shortname,
            options: { required: "Short name is required" }
          }}/>
      </FormRow>

      <Input 
        labelText="Doxim Key"           
          form={{
          register,
          name: "doximKey"}}/>
      <Input 
        labelText="Doxim Secret"            
        form={{
          register,
          name: "doximSecret"}}/>
      
      <FormRow>
        <Input 
          labelText="Custom Color"            
          form={{
            register,
            name: "customColor"}}/>
        <Input 
          labelText="Custom Link"            
          form={{
            register,
            name: "customLink"}}/>
      </FormRow>
      <label htmlFor="custom-brand">Custom Brand</label>
      <textarea id="custom-brand" {...register("customBrand")}/>

      <Button type="submit">
        {submitButtonText}
      </Button>
    </form>
  );
}