export class ProgressExtrapolator {
  constructor(timeEstimateMs, checkRealProgress, onProgress=null, maxProgress=100){
    this._totalTimeEstimate = timeEstimateMs;
    this._totalSteps = maxProgress;
    this._ratio = .5;
    this._checkReal = checkRealProgress; //can return undefined if no progress (return; or return undefined;)
    this.onProgress = onProgress;
    
    this._step = 0;
    this._prevReal = 0;
    this._prevStepAtCheck = 0;
    this._reconcileStep = 0;
    this._stepper = null;
    this._stepTime = NaN; //NaN means don't step
    this._gapCorrection = 1;
    this._minFinalCheckIntvlMs = 1000;
  }

  get progress() { return this._step; }

  start(){
    this._step = 0;
    this._prevReal = 0;
    this._prevStepAtCheck = 0;
    this._gapCorrection = 1;
    
    this.#scheduleNextReconcile(0);
    clearTimeout(this._stepper);
    this._stepTime = this._totalTimeEstimate/this._totalSteps
    this._stepper = setTimeout(this.#doStep.bind(this), this._stepTime);
  }

  get #remaining(){
    return this._totalSteps - this._step;
  }
  
  get totalSteps(){
    return this._totalSteps;
  }

  get completionRatio(){
    return this._step/this._totalSteps;
  }

  //return the real progress or the prev progress if error, or no return value (assume no progress)
  async #checkRealSafe(){
    try {
      let real = await this._checkReal();
      //if we didn't get a real value, assume no progress
      if (real === undefined) {
        real = this._prevReal;
      }
      return real;
    } catch {
      return this._prevReal;
    }
  }

  /**
   * Schedules the next reconcile based on how confident we are in our estimates, and how much progress is left(need to check more towards the end) 
   * @param {float} confidence confidence is a number between 0 and 1 representing how confident we are in our estimates currently
   */
  #scheduleNextReconcile(confidence=1){
    let stepsTillNext = Math.floor(
      (confidence*.75+.25) * this.#remaining * (this.completionRatio*this._ratio)
    );
    stepsTillNext += (stepsTillNext===0);// wait at least 1 step if 0
    this._reconcileStep = this._step + stepsTillNext
  }

  /**
   * Compares the estimated progress to the real progress and adjusts speed accordingly, temporally smoothed.
   * @param {boolean} force whether to an immediate update of the estimate to match the real progress (normally it's temporally smoothed)
   * @param {boolean} forceLowConfidence whether to force a low confidence in the estimate (normally it's based on how well we've been guessing), used to cause it to check again sooner
   * @returns 
   */
  async reconcile(force=false, forceLowConfidence=false){
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    this._reconcileStep = NaN;//prevent double dipping

    //sample before and after to account for any latency to a remote process
    let real, stepAtCheck; {
      const stepSample1 = this._step;
      real = await this.#checkRealSafe();

      const stepSample2 = this._step
      if (real >= this._totalSteps){
        this.finish();
        return;
      }
      stepAtCheck = (stepSample1+stepSample2)/2;
    }

    let stepsPassed = stepAtCheck - this._prevStepAtCheck;
    let realPassed = real - this._prevReal;

    let confidence = 0;
    if (forceLowConfidence) {
      confidence = 0;
    } else if (stepsPassed>0 && realPassed>0) {
      //use ratio of real vs estimated so see how well we guessed
      confidence = stepsPassed < realPassed ?
        stepsPassed/realPassed:
        realPassed/stepsPassed;
    }

    if (force) {
      this._step = real;
    }

    this.#scheduleNextReconcile(confidence);

    //adjust step rate such that the expected step at next reconcile is the same as the real step
    if (stepsPassed > 0) {
      //see how well we guessed the last progress
      let timeDeviationRatio = (realPassed > 0)? stepsPassed/realPassed : 3;
      timeDeviationRatio = clamp(timeDeviationRatio, 1/3, 3)
      timeDeviationRatio *= this._gapCorrection; //need to undo gap correction to ignore it's effect
      //we have to undo gap correction here too
      let estimatedRealStepTime = (this._stepTime/this._gapCorrection) * timeDeviationRatio;

      {// gap correction
        let stepsTillNext = this._reconcileStep - this._step;
        let realStepsTillNext = this._reconcileStep - real;
        this._gapCorrection = clamp(realStepsTillNext/stepsTillNext, 1/3, 3);
      }

      this._stepTime = clamp(
        estimatedRealStepTime * this._gapCorrection, //predicted
        this._totalTimeEstimate/this._totalSteps/64, //don't speed up too much
        this._totalTimeEstimate/this._totalSteps*2, //incase progress stalls, don't slow down TOO much, (this is more restrictive then max clamp because theres often artificial progress stalling on server)
      );
    }

    this._prevReal = real;
    this._prevStepAtCheck = stepAtCheck;
  }

  #doStep(){
    if (this._step+1 === this.totalSteps) {
      // WAITING FOR FINAL UPDATE

      //just waiting for finish, so consistent steps and no extra logic
      this.#checkRealSafe().then((real) => {
        if (real >= this._totalSteps){
          this.finish();
        } else {
          this._stepper = setTimeout(
            this.#doStep.bind(this), 
            Math.max( // wait at least min time or estimated step time, for less checking for really big processes
              this._totalTimeEstimate/this._totalSteps,
              this._minFinalCheckIntvlMs
            )
          );
        }
      });
    } else {
      // NORMAL PROGRESS

      //step and notify
      this._step++;
      if (this.onProgress) this.onProgress(this._step)

      //reconcile and schedule next step
      if (this._step < this._totalSteps) {
        if (!isNaN(this._reconcileStep) && this._step >= this._reconcileStep) {
          this.reconcile()
        }
        if (!isNaN(this._stepTime)){
          this._stepper = setTimeout(this.#doStep.bind(this), this._stepTime);
        }
      }
    }
  }

  finish(){
    this._step = this._totalSteps;
    this._stepTime = NaN;//tell stepper not to reset itself if in step now
    clearTimeout(this._stepper);
    if (this.onProgress) this.onProgress(this._step);
  }

}

