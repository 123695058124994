import axios from "axios";
import { useEffect, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import appConfig from "Config";
import { IMPORT_STATES, IMPORT_STATE_MAP } from "constants/importStates";
import { downloadFile, formatDateTimeStr } from "Utils";
import Button from "components/Buttons/Button";
import InfoButton from "components/Buttons/InfoButton";
import PMGDataTable from "components/DataTable/PMGDataTable";
import PageLoader from "components/Loaders/PageLoader";
import SectionHeading from "components/SectionHeading";
import ConfirmDeleteDialog from "components/Dialogs/ConfirmDeleteDialog";
import ImportDetailsDialog from "./ImportDetailsDialog";
import "../../../style/ModalDialog.css";

library.add(faTrashCan);

function ManageImports() {
  function stateColumnSelector(imp, state) {
    return (
      <>
        {IMPORT_STATE_MAP[state]}
        {state === IMPORT_STATES.processing_error &&
          <InfoButton onClick={() => errorInfoClickHandler(imp)} />
        }
      </>
    );
  }

  const columns = [
    { name: "ID", selector: row => row[0], sortable: true },
    { name: "Credit union", selector: row => row[1], sortable: true },
    { name: "Filename", selector: row => row[2], sortable: true },
    { name: "State", selector: row => stateColumnSelector(row, row[3]), sortable: true },
    { name: "Auto Processing", selector: row => (row[4] === undefined || row[4] === null) ? "Unknown" : row[4] === true ? "Yes" : "No", sortable: true },
    { name: "Timestamp", selector: row => formatDateTimeStr(row[5]), sortable: true },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [imports, setImports] = useState();
  const [selectedImports, setSelectedImports] = useState([]);
  const [clearSelectedImports, setClearSelectedImports] = useState(false);
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);
  const [disableDeleteButton, setDisableDeleteButton] = useState(true);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [clickedImport, setClickedImport] = useState(null);
  const [isErrorDetailsDialogOpen, setIsErrorDetailsDialogOpen] = useState(false);

  useEffect(() => {
    async function getImports() {
      try {
        const response = await axios.get(appConfig.baseURL + "api/superuser/imports");

        const newImports = response.data.map(imp => {
          return [
            imp.id,
            imp.credit_union,
            imp.filename,
            imp.state,
            imp.is_auto_processing,
            imp.timestamp,
            imp.errors,
          ];
        });

        setImports(newImports);
      } catch (error) {
        console.log(error);
      }
    }

    getImports();
  }, [setImports]);

  useEffect(() => {
    setDisableDeleteButton(selectedImports.length === 0);
    setDisableDownloadButton(selectedImports.length === 0 || selectedImports.filter(imp => !imp[2]).length);
  }, [selectedImports]);

  function selectedImportHandler(allSelected, selectedCount, selectedRows) {
    setSelectedImports(selectedRows);
  }

  function resetSelectedImports() {
    setSelectedImports([]);
    setClearSelectedImports(!clearSelectedImports);
  }

  function selectableImportDisabledHandler(imp) {
    return imp[3] === IMPORT_STATES.processing;
  }

  async function downloadRawDataFileHandler() {
    setIsLoading(true);

    // Get id of all selected imports
    const importIds = selectedImports?.map(row => row[0]).join(",");

    const config = {
      params: {
        import_ids: importIds
      },
      responseType: "blob",
    };

    try {
      const response = await axios.get(appConfig.baseURL + "api/superuser/imports/download/raw", config);

      downloadFile(response.data, "files.zip");

      resetSelectedImports();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function deleteImportHandler() {
    setIsDeleteDialogOpen(true);
  }

  async function deleteImportConfirmedHandler() {
    setIsDeleteDialogOpen(false);
    setIsLoading(true);

    // Send request to delete the imports
    const config = {
      data: {
        import_ids: selectedImports?.map(imp => imp[0])
      }
    };

    try {
      const { data } = await axios.delete(appConfig.baseURL + "api/superuser/imports", config);

      const newFiles = imports.filter(imp => !data.includes(imp[0]));

      setImports(newFiles);

      resetSelectedImports();

      toast.success("Imports(s) deleted");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  function errorInfoClickHandler(imp) {
    setClickedImport(imp);
    setIsErrorDetailsDialogOpen(true);
  }

  return (
    <div className="container mx-auto px-4 pb-2">
      <PageLoader loading={isLoading} />

      <SectionHeading noPadding>Manage imports</SectionHeading>

      <p className="mb-2">
        Imports for all credit unions are listed in the table below. This page can be used to download raw data
        from an import and delete imports.
      </p>
      <p>
        <span className="font-semibold">Note: </span>
        You cannot delete imports that are still processing.
      </p>

      <PMGDataTable
        data={imports}
        columns={columns}
        selectableRows
        onSelectedRowsChange={selectedImportHandler}
        clearSelectedRows={clearSelectedImports}
        selectableRowDisabledHandler={selectableImportDisabledHandler} />
      <div className="flex gap-1">
        <Button disabled={disableDownloadButton} onClick={downloadRawDataFileHandler}>
          <FontAwesomeIcon className="mr-1" icon="download" />Download
        </Button>
        <Button disabled={disableDeleteButton} onClick={deleteImportHandler}>
          <FontAwesomeIcon className="mr-1" icon={faTrashCan} />Delete
        </Button>
      </div>

      <ConfirmDeleteDialog
        open={isDeleteDialogOpen}
        onDelete={deleteImportConfirmedHandler}
        onCancel={() => setIsDeleteDialogOpen(false)} />

      <ImportDetailsDialog
        importRecord={clickedImport}
        open={isErrorDetailsDialogOpen}
        onClose={() => setIsErrorDetailsDialogOpen(false)}
        columns={columns} />
    </div>
  );
}

export default ManageImports;
