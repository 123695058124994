import { useRef, useState } from "react";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import appConfig from "Config";
import { downloadFile } from "Utils";
import Checkbox from "components/UserInput/Checkbox";
import ModalDialog from "components/Dialogs/ModalDialog";
import Button from "components/Buttons/Button";
import ReportFilter from "components/Dashboard/ReportFilter";
import PageLoader from "components/Loaders/PageLoader";
import Error from "components/Error";

const LSS_MAP = {
  "D1": "Developing D1: Engagers",
  "D2": "Developing D2: Passives",
  "D3": "Developing D3: Strivers",
  "E1": "Establishing E1: Engagers",
  "E2": "Establishing E2: Passives",
  "E3": "Establishing E3: Adjusters",
  "T1": "Transitioning T1: Engagers",
  "T2": "Transitioning T2: Critical Adjusters",
  "T3": "Transitioning T3: Adjusters",
  "M1": "Maturing M1: Engagers",
  "M2": "Maturing M2: Passives",
  "R1": "Realization R1: Achievers",
  "R2": "Realization R2: Survivors",
  "N/A": "N/A",
};

const FORM_ID = "export-report-form";

function ExportReportForm({ open, onCloseCallback }) {
  const methods = useForm({
    defaultValues: {
      filters: [
        {
          category: "",
          operator: "",
          value: "",
        },
      ]
    }
  });
  const { control, handleSubmit, reset, getValues, formState: { errors } } = methods;
  const { fields, append, remove } = useFieldArray({
    control: control, // control from useForm()
    name: "filters",  // Unique name for field array
  });

  const [loading, setLoading] = useState(false);
  
  const formEndRef = useRef();

  async function onSubmit(data) {
    try {
      setLoading(true);

      const selectedLss = Object.keys(data.lss).filter(key => data.lss[key] === true);

      const match = buildMatch(selectedLss, data.filters);

      const config = {
        responseType: "blob", // This is required for downloading a ZIP file (if it's not included the ZIP will be corrupted)
      };
      
      const response = await axios.post(`${appConfig.baseURL}api/dashboard/reports`, { 
        match,
        reportType: data.reportType,
      }, config);
      
      if (response.status === 200) {
        downloadFile(response.data, "report.zip");
      } else {
        toast.info(
          <div className="text-left">
            <h1 className="font-semibold">Export Report</h1>
            No data available
          </div>
        );
      }
    } catch (error) {
      console.log(error.response ?? error); 
    } finally {
      reset();

      setLoading(false);

      onCloseCallback();
    }
  }

  function onCancel() {
    reset();
    
    onCloseCallback();
  }

  function buildMatch(selectedLss, filters) {   
    const match = {
      "lss": { "$in": selectedLss.length > 0 ? selectedLss : Object.keys(LSS_MAP) },
      "$and": [],
    };

    filters?.forEach(filter => {
      // Only use the filter if all fields are provided
      if (filter.category && filter.operator && filter.value) {      
        const matchItem = {}
        matchItem[filter.category.value] = {};
        
        // mortgage_count is a special case because the form shows yes/no but it's stored as a number in the database
        if (filter.category.value === "mortgage_count") {
          matchItem[filter.category.value] = filter.value.value === 1 ? { "$gt": 0 } : { "$eq": 0 }
        } else { 
          // String values are from a textbox, all other values are from dropdown lists
          if (typeof(filter.value) === "string") {
            matchItem[filter.category.value][filter.operator.value] = isNaN(filter.value) ? filter.value : parseFloat(filter.value);
          } else {
            matchItem[filter.category.value][filter.operator.value] = filter.value.value;
          }

        }
        
        match["$and"].push(matchItem);
      }

    });

    // Remove $and if it's empty
    if (match["$and"].length === 0) {
      return {
        lss: match.lss
      };
    }
    
    return match;
  }

  function addFilter() {
    append({ category: "", operator: "", value: ""  });

    // Timer is needed because you can't scroll until the filter has been added to the DOM
    setTimeout(() => {
      formEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 1);
  }

  return (
    <>
      <PageLoader loading={loading}/>

      <ModalDialog
        title="Export Report"
        open={open}
        formId={FORM_ID}
        positiveButtonText="Export"
        positiveButtonClassName="ok-btn"
        onPositiveButton={handleSubmit(onSubmit)}
        negativeButtonText="Cancel"
        onNegativeButton={onCancel}
        onClose={onCancel}
        titleClassName="px-1"
        contentClassName="overflow-y-scroll h-96 px-1"
        size="xl">
        <FormProvider {...methods}>
          <form id={FORM_ID} className="flex flex-col gap-4" onSubmit={event => event.preventDefault()}>
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Select report type:</span>
              <Checkbox 
                name="reportType.summary" 
                label="Summary report" 
                formOptions={{ validate: value => (value || getValues("reportType.member")) || "Report type is required" }}/>
              <Checkbox 
                name="reportType.member" 
                label="Member report"/>
              <Error>{errors.reportType?.summary.message}</Error>
            </div>

            <div className="flex flex-col gap-1">
              <span className="font-semibold">Select life stage segments:</span>

              {(() => {
                const keys = Object.keys(LSS_MAP);
                const splitIndex = Math.ceil((keys.length - 1) / 2);

                return (
                  <div className="flex gap-8">
                    <div className="flex flex-col gap-1">
                      {keys.slice(0, splitIndex).map(key => (
                        <Checkbox name={`lss.${key}`} key={`lss-checkbox-${key}`} label={LSS_MAP[key]}/>
                      ))}
                    </div>
                    <div className="flex flex-col gap-1">
                      {keys.slice(splitIndex).map(key => (
                        <Checkbox name={`lss.${key}`} key={`lss-checkbox-${key}`} label={LSS_MAP[key]}/>
                      ))}
                    </div>
                  </div>
                );
              })()}
            </div>
            
            <div className="flex flex-col gap-1">
              <span className="font-semibold">Data filters:</span>
              <i>Filters with fields missing will be ignored.</i>

              <div className="flex flex-col justify-between gap-5">
                {fields.map((item, index) => 
                  <ReportFilter
                    key={`report-filter-${index}`}
                    index={index}
                    control={control}
                    field={item}
                    remove={remove}/>
                )}
                <Button id="add-button" className="h-fit w-fit" onClick={addFilter}>
                  Add
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>

        <div ref={formEndRef}/>
      </ModalDialog>
    </>
  );
}

export default ExportReportForm;