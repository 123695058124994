import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "style/Header.css";
import HeaderLink from "components/Header/HeaderLink";
import logout from "components/Login/Logout.js";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [superuser, setSuperuser] = useState(false);
  const currentPage = useLocation().pathname;

  let user = "";
  user = localStorage.getItem("user");
  if (!!user) {
    user = JSON.parse(user);
  }

  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    setSuperuser(user?.superuser ?? false);

    setLoggedIn(!!user ? true : false);
  }, [user, currentPage]);

  function handleLogout() {
    logout();
  }

  return (
    <header className={"Header sticky top-0 left-0 " + (currentPage === "/" || currentPage === "/login" ? "NoBG" : "")}>
      <div className="container md:mx-auto px-4">
        {/* ----------New navbar BEGIN------------ */}
        <div className="py-2 md:flex md:justify-between">
          <div className="flex items-center justify-between py-2">
            <div>
              <Link to="/">
                <img
                  src="https://www.pmgintelligence.com/wp-content/uploads/2019/02/PMG_Logo-1@2x.png"
                  className="jet-logo__img"
                  alt="PMG Intelligence"
                  width="126"
                  height="60"
                ></img>
              </Link>
            </div>

            {/* Hamburger menu for mobile */}
            {(currentPage !== "/" && currentPage !== "/login") &&
            <div className="md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="block border p-2 rounded-md text-gray-200 hover:text-white  focus:outline-none focus:ring-2 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>}
          </div>
          {(currentPage !== "/" && currentPage !== "/login") &&
          <div className={`px-2 pb-3 ${isOpen ? "block" : "hidden"} md:flex md:items-center md:p-0`}>
            {/* <Link
              className="md:mt-0 md:ml-3 block px-2 py-1 text-white font-semibold hover:bg-blue-500"
              to="/"
            >
              Home
            </Link> */}
            {loggedIn ? 
              <>
                {superuser ? 
                  <>
                    <HeaderLink show="true" to="superuser/credit-union/create">
                      Create credit union
                    </HeaderLink>
                    <HeaderLink show="true" to="superuser/credit-union/manage">
                      Manage credit union
                    </HeaderLink>
                    <HeaderLink show="true" to="superuser/imports/manage">
                      Manage imports
                    </HeaderLink>
                  </>
                :
                  <>
                    <HeaderLink show="true" to="credit-union/input">
                      Input
                    </HeaderLink>

                    <HeaderLink show="true" to="credit-union/dashboard">
                      Dashboard
                    </HeaderLink>
                  </>
                }
                <HeaderLink show="true" to="/" onClick={handleLogout}>
                  Logout
                </HeaderLink>
              </>
            : 
              <HeaderLink show to="/">
                Login
              </HeaderLink>
            }
          </div>}
        </div>
        {/* ----------New navbar END------------ */}
      </div>
    </header>
  );
};

export function calculation(a) {
  return a * 4;
}

export default Header;
