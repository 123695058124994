import React, { useEffect, useState, createContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import appConfig from "Config";
import PageLoader from "components/Loaders/PageLoader";
import Timestamp from "components/Iframe/Timestamp";
import Retention from "components/Iframe/Retention";
import ProductSummaryAndOpportunities from "components/Iframe/ProductSummaryAndOpportunities";
import IndustryEngagement from "components/Iframe/IndustryEngagement";
import WalletShare from "components/Iframe/WalletShare";
import Trajectory from "components/Iframe/Trajectory";
import Advice from "components/Iframe/Advice";
import Recommendations from "components/Iframe/Recommendations";
import Insights from "components/Iframe/Insights";
// import Ownership from "../components/Iframe/Ownership";
//import OwnershipDetailed from "../components/Iframe/OwnershipDetailed";
import ProfileNotFound from "components/Iframe/ProfileNotFound";
import IframeFooter from "components/Iframe/IframeFooter";
import { useQuery } from "Utils";
import "style/Bootstrap.scss";
import "style/Iframe.css";
import Header from "components/Iframe/Header";

/**
 * @note We don't need `member_profile_order` in the context because it's only used
 * in the top-level component, but keeping all configuration together keeps it simple.
 * 
 * @typedef {{
 *  member_profile_order: string[][],
 *  member_profile_question_mark_icon: boolean;
 * }} MemberProfileConfiguration
 */

/**
 * @type {React.Context<MemberProfileConfiguration>}
 */
export const MemberProfileConfigurationContext = createContext(null);

/**
 * Creates an object that maps the names of each section of the profile to its components and props.
 * 
 * @note
 * This could be moved to a utils file, but it's here for now so we can more easily change the structure of the data if needed.
 * 
 * @param {{
 *  advices: string[];
 *  age: number;
 *  balance: object;
 *  engagement_opportunities: string[];
 *  lss: object;
 *  ownership: object;
 *  product_summary: object[];
 *  recommendations: string[];
 *  retention: object;
 *  tenure: number;
 *  timestamp: string;
 *  trajectory: object;
 *  wallet_share: object;
 *  warning: boolean;
 * }} memberProfileData The data for the member profile.
 * @param {string} doximKey The doxim key for the credit union.
 * @param {string} uid The UID that corresponds to the member profile data.
 */  
function createComponentMap(memberProfileData, doximKey, uid) {
  // The keys for this object should match the values of the MemberProfileSection enum defined in the backend.
  return {
    retention_score: {
      component: Retention,
      props: {
        data: memberProfileData.retention,
        doximKey,
        uid,
      },
      render: !!memberProfileData.retention,
    },
    product_summary_and_opportunities: {
      component: ProductSummaryAndOpportunities,
      props: {
        products: memberProfileData.product_summary,
        engagementOpportunities: memberProfileData.engagement_opportunities,
        doximKey,
        uid,
      },
      render: !!memberProfileData.product_summary && !!memberProfileData.engagement_opportunities,
    },
    industry_engagement: {
      component: IndustryEngagement,
      props: {
        data: memberProfileData.lss,
        doximKey,
        uid,
      },
      render: !!memberProfileData.lss,
    },
    estimated_share_of_wallet: {
      component: WalletShare,
      props: {
        walletShareData: memberProfileData.wallet_share,
        lssData: memberProfileData.lss,
        doximKey,
        uid,
      },
      render: !!memberProfileData.lss,
    },
    trajectory: {
      component: Trajectory,
      props: {
        data: memberProfileData.trajectory,
        doximKey,
        uid,
      },
      render: !!memberProfileData.trajectory,
    },
    advice_factors: {
      component: Advice,
      props: {
        data: memberProfileData.advices,
        doximKey,
        uid,
      },
      render: !!memberProfileData.advices,
    },
    conversations_to_enhance_relationship: {
      component: Recommendations,
      props: {
        data: memberProfileData.recommendations,
        doximKey,
        uid,
      },
      render: !!memberProfileData.recommendations,
    },
    insights: {
      component: Insights,
      props: {
        data: memberProfileData.lss,
      },
      render: !!memberProfileData.lss,
    }
  };
};

export default function Iframe() {
  const params = useParams();
  const query = useQuery();

  const uid = query.get("FocusId");
  const doximKey = params.doxim_key;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [componentMap, setComponentMap] = useState(null);
  const [configuration, setConfiguration] = useState(null);

  useEffect(() => {
    async function getMemberProfileData() {
      const config = {
        params: {
          FocusId: query.get("FocusId"),
          PlainTextFocusId: query.get("PlainTextFocusId") ?? false,
          ImportId: query.get("ImportId"),
        }
      };

      const response = await axios.get(`${appConfig.baseURL}api/member-profile/data/${params.doxim_key}`, config);

      return response.data;
    }

    async function getMemberProfileConfiguration() {
      const response = await axios.get(`${appConfig.baseURL}api/member-profile/configuration/${params.doxim_key}`);

      return response.data;
    }

    async function getData() {
      try {
        const data = await getMemberProfileData();
        setData(data);

        const memberProfileConfiguration = await getMemberProfileConfiguration();

        setConfiguration(memberProfileConfiguration);
        setComponentMap(createComponentMap(data, doximKey, uid));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);

        const app = document.querySelector('.iframe');
        const height = app.clientHeight;
        window.parent.postMessage(
          { height },
          '*'
        );
      }
    }

    getData();
  }, [params, query]);

  return (
    <>
      {loading ?
        <PageLoader loading={loading}/>
      :
        <MemberProfileConfigurationContext.Provider value={configuration}>
          <div className="iframe scoped-bootstrap">
            <div className="container-fluid p-4">
              <div className="row no-padding">
                {data ?
                  <>
                    <div className={`col-12 ${data.warning && "mb-3"}`}>
                      <div className={`warning ${data.warning && "d-block"}`}>
                        Warning: Some calculations are not available for this member due to insufficient information. Please contact your system administrator.
                      </div>
                    </div>

                    {data.lss &&
                      <>
                        <Header data={data.lss} trajectory={data.trajectory?.trajectory} tenure={data.tenure}/>
                        {data.lss.timestamp &&
                          <Timestamp value={data.lss.timestamp}/>
                        }
                      </>
                    }

                    {configuration.member_profile_order.map(row => 
                      row.map((componentName, index) => {
                          if (componentMap[componentName].render) {
                            return React.createElement(componentMap[componentName].component, { 
                              key: `member-profile-widget-${index}`, 
                              ...componentMap[componentName].props,
                            })
                          }
                        }
                      )
                    )}
                  </>
                :
                  <ProfileNotFound/>
                }
              </div>

              <IframeFooter/>
            </div>
          </div>
        </MemberProfileConfigurationContext.Provider>
      }
    </>
  );
}