export default function IframeFooter() {
  return (
    <div className="row">
      <h6>
        <div style={{display: "flex", alignItems: "center", justifyContent: "end"}}>
          Powered by 
          <img src="/PMG_Logo-C-1@2x.png" alt="PMG Intelligence" width="100" />
        </div>
      </h6>
      <br/>
    </div>
  );
}