import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

library.add(faXmark);

function XMark() {
  return (
    <FontAwesomeIcon
      className="text-red-600"
      icon="fa solid fa-xmark"
      data-testid="xmark"
    />
  );
}

export default XMark;