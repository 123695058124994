import { useLocation } from "react-router-dom";

function NotFound() {
  const location = useLocation();
  
  console.log(location);

  return (
    <div className="flex-grow">
      <h1 className="text-2xl font-bold text-center py-10">
        Page not found at {location.pathname}
      </h1>
    </div>
  );
}

export default NotFound;