import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

library.add(faCheck);

function CheckMark() {
  return (
    <FontAwesomeIcon
      className="text-green-700"
      icon="fa-solid fa-check"
      data-testid="checkmark"
    />
  );
}

export default CheckMark;