import "style/Iframe.css";
import { useState } from "react";

export default function Insights({ data }) {
  const [collapseInsights, setCollapseInsights] = useState(true);

  return (
    <div className="col-12">
      <div className="widget borderless">
        <div className="inner lss">
          <h2 className="subtitle iframe-heading" style={{textAlign: "left"}}>Insights into connecting with a {data.code}</h2>
          {data.segment_topics &&
            <div className="row text-left insights" style={{marginLeft: "0"}}>
              <div className="col-12 col-sm-6 col-md-3">
                <strong>Key personal values</strong>
                <ol>
                  {data.segment_topics[0].map(value => 
                    <li key={value}>{value}</li>  
                  )}
                </ol>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <strong>Near term plans</strong>
                <ol>
                  {data.segment_topics[1].map(value => 
                    <li key={value}>{value}</li>  
                  )}
                </ol>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <strong>Key Challenges</strong>
                <ol>
                  {data.segment_topics[2].map(value => 
                    <li key={value}>{value}</li>  
                  )}
                </ol>
              </div>
              <div className="col-12 col-sm-6 col-md-3">
                <strong>Connection to money</strong>
                <ol>
                  {data.segment_topics[3].map(value => 
                    <li key={value}>{value}</li>  
                  )}
                </ol>
              </div>
            </div>
          }

          <div className="row text-left insights" >
            <div className="col-12">
              <h6 className="text-right iframe-heading">
                <button className="expand link font-medium" onClick={() => setCollapseInsights(!collapseInsights)}>
                    <span className="mr-1">View insights</span>
                    <img className="inline-block" src={`${collapseInsights ? "/chevron-down.png" : "/chevron-up.png"}`} alt="up/down arrow" width={20}/>
                </button>
              </h6>
              {data.segment_insights &&
                <>
                  {Object.keys(data.segment_insights).map(key => 
                    <div key={key}>
                      <h5 className="iframe-heading">{key}</h5>
                      {!collapseInsights &&
                        <div className="pb-4">
                          {data.segment_insights[key]}
                        </div>
                      }
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}