import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import "style/Footer.css";

library.add(faGlobe)

class Footer extends Component {
  /* constructor(){
    super();
  } */

  render() {
    const currentPage = window.location.pathname;
    return (
      <div className={"Footer pt-2 " + (currentPage === "/" || currentPage === "/login" ? "NoBG" : "")}>
        <div className="container mx-auto px-4 py-5">
          <div className="flex items-center justify-between">
            <div>
              <img
                src="https://www.pmgintelligence.com/wp-content/uploads/2019/02/PMG_Logo-1@2x.png"
                className="jet-logo__img"
                alt="PMG Intelligence"
                width="126"
                height="60"
              ></img>
            </div>
            <div className="flex items-center">
              {/* <Link
                className="md:mt-0 md:ml-3 block px-2 py-1 text-white font-semibold hover:bg-blue-500"
                to="contact"
              >
                Contact
              </Link> */}

              {/* <Link
                className="md:mt-0 md:ml-3 block px-2 py-1 text-white font-semibold hover:bg-blue-500"
                to="about"
              >
                About PMG360
              </Link> */}

              <a
                className="md:mt-0 md:ml-3 block px-2 py-1 text-white font-semibold hover:bg-blue-500"
                href="https://pmgintelligence.com"
                target="_blank" 
                rel="noreferrer"
              >
                <FontAwesomeIcon icon="globe" /> Visit PMG Intelligence website
              </a>
            </div>
          </div>
          <hr className="hr my-5" />
          <div className="text-white text-center">
            &copy; {this.props.year} Creation All Rights Reserved -{" "}
            <a href="https://www.pmgintelligence.com/">PMG Intelligence</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
