import React, { Component } from "react";
import CUDataTable from "components/DataTable/CUDataTable";
import CUFileUpload from "components/Input/CUFileUpload";
import Instructions from "components/Input/Instructions.js";
import Brand from "components/Brand.js";
import SectionHeading from "components/SectionHeading.js";
import "style/CreditUnion.css";

const PERSISTENT_STATE = 'input_persistent';

class Input extends Component {
  
  constructor(props) {
    super(props);

    this.persistentState = JSON.parse(localStorage.getItem(PERSISTENT_STATE)) || {};
    this.state = {
      import_title: '',
      ...this.persistentState,
    };
  }

  setStatePersistently(state) {
    Object.assign(this.persistentState, state);
    console.debug("setStatePersistenly, state: " + JSON.stringify(state))
    localStorage.setItem(PERSISTENT_STATE, JSON.stringify(this.persistentState));
    this.setState(state);
  }

  render() {
    return (
      <div className="CreditUnion text-center">
        <Brand/>
        <SectionHeading>Start PMG Data Processing</SectionHeading>
        <CUFileUpload
          import_processing= {this.state.import_processing}
          import_id={this.state.import_id}
          onFileUpload={({importTitle, importId}) => {
            console.debug("onFileUpload, importTitle: " + importTitle + ", importId: " + importId)
            this.setStatePersistently({
              import_title: importTitle,
              import_id: importId,
              import_processing: true,
            });
          }}
          onDone={(success) => {
            console.debug("onDone, wasSuccess: " + success)
            let stateMod = { import_processing: false }
            if (!success) {
              stateMod.import_id = null
              stateMod.import_title = null
            }
            this.setStatePersistently(stateMod);
          }}
        />

        {this.state.import_id && !this.state.import_processing ? (
          <CUDataTable import_title={this.state.import_title} import_id={this.state.import_id} />
        ) : (<></>) }
        
        <SectionHeading>Instructions</SectionHeading>
        <Instructions/>
        
        <div className="flex flex-grow py-5"></div>

      </div>
    );
  }
}

export default Input;
