import React from "react";
import Login from "components/Login/Login";

class Homepage extends React.Component{
  constructor(props) {
    super(props);
    console.log("Homepage");
  }

  render() {
    return (
      <div className="Homepage py-10">
        <div className="flex flex-wrap items-center justify-center">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 p-5 items-center md:w-3/4">
            <div className="text-lg">
              <h1 className="text-4xl py-5 mb-10 font-bold">
                Welcome to PMG 360
              </h1>
              A consumer behaviour segmentation and predictive analytics technology used to inform what, when and how to approach current clients to educate and position solutions that improve financial outcomes.

              <Login />
            </div>

            <div>
              {/* <img
                src="https://www.pmgintelligence.com/wp-content/uploads/2020/05/Changing-Industry-2048x1245.png"
                className="pmg360 md:w-full md:h-full sm:w-2/3 sm:h-2/3"
                alt="PMG360"
              ></img> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
