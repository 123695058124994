import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import axios from 'axios';
import attachTokenRefreshingInterceptor from "TokenRefreshingInterceptor.js"

axios.defaults.withCredentials = true;

attachTokenRefreshingInterceptor(axios)

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);
