import HeadingContainer from "components/Iframe/HeadingContainer";
import FeedbackButtons from "components/Iframe/FeedbackButtons";
import IframeInfoButton from "components/Iframe/IframeInfoButton";

export default function Recommendations({ data, doximKey, uid }) {
  return (
    <div className="col-12 col-md-6">
      <div className="widget">
        <div className="inner">
          <h2 className="subtitle iframe-heading">
            <HeadingContainer className="justify-center">
              Conversations to Enhance Relationship
              <IframeInfoButton
                tooltip="A list of conversations and recommendations to meet the product and guidance 
                needs of the average consumer within the member's sub-segment."/>
              <FeedbackButtons doximKey={doximKey} uid={uid} widget="Recommendations"/>
            </HeadingContainer>
          </h2>
          <ul>
            {data.map(recommendation =>
              <li key={recommendation}>{recommendation}</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}