import React, { Component } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import appConfig from "Config.js"
import Button from "components/Buttons/Button.js";
import * as Sentry from "@sentry/browser";
import logout from "components/Login/Logout.js";

import { withInternalReferrer } from "components/Layout"; 

class Login extends Component {
  rendered = false;

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
      referrer: props.referrer,
    };
  }

  static get isLogged(){
    return 'user' in localStorage;
  }
  static get isSuperuser() { 
    const user = JSON.parse(localStorage.getItem('user'));
    return !!user?.superuser;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      username: this.username,
      password: this.password,
    };

    axios
      .post(appConfig.baseURL + "api/auth", data)
      .then((response) => {
        if (response.data.user) {
          const user = response.data.user;
          const org_info = response.data.org_info;

          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("org_info", JSON.stringify(org_info));

          Sentry.setUser({username: user.username});

          this.setState({message: '' });
          window.location.href = Login.isSuperuser? "/superuser/credit-union/create" : "/credit-union/input";
        } else if (response.data.msg) {
          this.setState({message: response.data.msg});
        }
        
        return response.data;
      })
      .catch((errors) => {
        logout();
        if (errors?.response?.data?.msg) {
          this.setState({ message: errors.response.data.msg });
        }
        console.log(errors);
      });
  };

  /**
   * Redirects to input page(or superuser page) if already logged in, and user comes to login page from an external place(a bookmark for example).
   * If it looks like the user is explicitly trying to navigate to this page, or that can't be determined, don't redirect.
   */
  shouldRedirect() {
    if (
      !Login.isLogged || // only if logged in
      !window?.performance?.getEntriesByType || // only if browser supports performance api, otherwise can't be sure where we are coming from, so do no redirection
      this.state.referrer // only if NOT coming from another page on same site
    ){
      return false;
    }

    const navEntries = window.performance.getEntriesByType("navigation");
    const lastNav = navEntries.length > 0 ? navEntries[navEntries.length - 1] : undefined;

    // only if actually navigating(not back button or reload or something), or if fresh page load(no last nav)
    return !lastNav || lastNav.type === 'navigate';
  }

  render() {
    if (!this.rendered){
      if (this.shouldRedirect()){
        let page = Login.isSuperuser? "/superuser/credit-union/create" : "/credit-union/input";
        return <Navigate to={page} replace={true} />;
      }
      this.rendered = true;
    }
    
    return (
      <div className="Login">
        <div className="loginForm flex flex-wrap">
          <div className="w-full max-w-md">
            <form
              action=""
              onSubmit={this.handleSubmit}
              className="block shadow-md rounded"
            >
              <div className="my-5">
                <label htmlFor="username-input" className="block text-sm font-bold mb-2">
                  Username
                </label>
                <input
                  id="username-input"
                  type="text"
                  className="shadown-md appearance-none border rounded w-full py-2 px-4"
                  placeholder="Credit Union Name"
                  onChange={(e) => (this.username = e.target.value)}
                />
              </div>
              <div className="my-5">
                <label
                  htmlFor="password-input"
                  className="block text-sm font-bold mb-2"
                >
                  Password
                </label>
                <input
                  id="password-input"
                  type="password"
                  className="shadown-md appearance-none border rounded w-full py-2 px-4"
                  placeholder="Enter Password"
                  onChange={(e) => (this.password = e.target.value)}
                />
              </div>
              <div className="flex items-center justify-between">
                <Button type="submit">
                  Login
                </Button>

                {/* <Link
                  className="inline-block align-baseline text-sm text-blue-500 hover:text-blue-600"
                  to="/contact"
                >
                  Forgot Password?
                </Link> */}

                <h5 className="w-full px-5">{this.state.message}</h5>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withInternalReferrer(Login);