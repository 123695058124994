import IframeInfoButton from "components/Iframe/IframeInfoButton";
import HeadingContainer from "../HeadingContainer";
import FeedbackButtons from "../FeedbackButtons";
import "style/Iframe.css";

function EngagementOpportunities({ opportunities, doximKey, uid }) {
  const filteredOpportunities = opportunities?.filter(opportunity => opportunity.value !== null);
  
  return (
    <>
      <h2 className="text-left-override w-full">
        <HeadingContainer>
          Top Engagement Opportunities
          <IframeInfoButton 
            tooltip="Consider offering these products/services to the member in order to enhance their 
            relationship with the credit union and potentially increase retention."/>
          <FeedbackButtons 
            doximKey={doximKey}
            uid={uid}
            widget="Engagement Opportunities" 
            buttonContainerClassName="ml-auto"/>
        </HeadingContainer>
      </h2>
      {filteredOpportunities?.length > 0 ?
        <ul className="text-left opportunities-list">
          {opportunities?.map((opportunity, index) => (
            <li key={`opportunity-${index}`}>
              {opportunity}
            </li>
          ))}
        </ul>
        :
        <>N/A</>
      }
    </>
  );
}

export default EngagementOpportunities;