import { Link } from "react-router-dom";

function HeaderLink({show, to, onClick, children}) {
  return (
    <>
      {show ?
      <Link
        className="md:mt-0 md:ml-3 block px-2 py-1 text-white font-semibold hover:bg-blue-500"
        to={to}
        onClick={onClick}
      >
        {children}
      </Link>
      :
      <></>}
    </>
  );
}

export default HeaderLink;