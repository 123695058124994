import React from "react";
import { Outlet } from "react-router-dom";
import SuperuserComponent from "components/Authentication/SuperuserComponent";

class Superuser extends React.Component {
  render() {
    return (
      <SuperuserComponent>
        <div className="my-5">
        </div>

        <Outlet />
      </SuperuserComponent>
    );
  };
}

export default Superuser;