import BarChart from "components/Charts/BarChart";

function PercentageChart({ title, data, groupMode, renderLabel, keys = [], colors = {"": ""} }) {
  function renderTooltip({id, color, data}) {
    return (
      <div className="flex gap-1 p-2 bg-white rounded-lg shadow-xl items-center">
        <div style={{background: `${color}`, width: "1rem", height: "1rem"}}/>
        {id} - 
        <span className="font-bold">{data[id]}%</span>
      </div>
    );
  };

  return (
    <BarChart
      title={title}
      data={data}
      groupMode={groupMode}
      renderLabel={renderLabel}
      axisLeftLabelFormat={value => `${value}%`}
      label
      keys={keys}
      colors={colors}
      renderTooltip={renderTooltip}
      axisLeftTitle="Percentage"
      maxValue={100}
      minValue={0}
      axisLeftTickValues={[0, 20, 40, 60, 80, 100]}/>
  );
}

export default PercentageChart;
