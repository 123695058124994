import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Button from "components/Buttons/Button";
import Input from "components/UserInput/Input";
import CopyButton from "components/Buttons/CopyButton";
import RefreshButton from "components/Buttons/RefreshButton";
import { generatePassword } from "Utils";
import Checkbox from "components/UserInput/Checkbox";

/**
 * @param {{
 *  onSubmit: (data, event, resetForm: () => {}, setError: () => {}) => void
 * }} props
 */
export default function CUPasswordForm({ onSubmit }) {
  const { register, formState: { errors }, reset, setError, getValues, setValue, handleSubmit } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(""); // Password needs to be stored in state so the copy button can access it

  function generatePasswordHandler() {
    const newPassword = generatePassword(10);

    setPassword(newPassword);
    setValue("newPassword", newPassword);
  }

  function resetForm() {
    setPassword("");
    reset();
  }

  return (
    <form className="flex flex-col gap-2 mx-auto w-11/12" onSubmit={handleSubmit((data, event) => onSubmit(data, event, resetForm, setError))}>
      <Input
        labelText="Current password"
        type="password"
        form={{
          register,
          name: "currentPassword",
          error: errors.currentPassword,
          options: { required: "Current password is required" }
        }}/>
      
      <Input 
        labelText="New password"
        type={showPassword ? "text" : "password"}
        autoComplete="new-password"
        value={password}
        onChange={value => setPassword(value)}
        form={{
          register,
          name: "newPassword",
          error: errors.newPassword,
          options: { required: "New password is required" }
        }}
        buttonComponent={
          <>
            <CopyButton value={password} onCopy={() => toast.success("Password copied to clipboard")} aria-label="copy-password-button"/>
            <RefreshButton onClick={generatePasswordHandler} aria-label="refresh-password-button"/>
          </>
        }/>
      <Checkbox label="Show password" onChange={() => setShowPassword(!showPassword)}/>

      <Input
        labelText="Confirm new password"
        type="password"
        autoComplete="new-password"
        form={{
          register,
          name: "confirmNewPassword",
          error: errors.confirmNewPassword,
          options: { 
            required: "You must confirm the new password",
            validate: {
              matchNewPassword: value => value === getValues("newPassword") || "The passwords do not match"
            }
          }
        }}/>

      <Button type="submit">
        Change password
      </Button>
    </form>
  )
}