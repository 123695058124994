import HeadingContainer from "components/Iframe/HeadingContainer";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import FeedbackButtons from "./FeedbackButtons";

export default function Trajectory({ data, doximKey, uid }) {
  return (
    <div className="col-6 col-md-3">
      <div className="widget">
        <div className="inner text-center">
          <h2 className="subtitle iframe-heading margin-override">
            <HeadingContainer className="justify-center">
              Trajectory
              <IframeInfoButton
                tooltip="The most likely path the member will take as they progress through the life stages. Members can move up, down, or straight."/>
              <FeedbackButtons doximKey={doximKey} uid={uid} widget="Trajectory"/>
            </HeadingContainer>
          </h2>
          {data.trajectory &&
            <img className="mx-auto" src={`/trajectory_${data.trajectory}.png`} alt="trajectory arrow"/>
          }
          {data.trend && 
            <>Most likely to transition to <strong>{data.trend}</strong></>
          }
        </div>
      </div>
    </div>
  );
}