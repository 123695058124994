import PropTypes from "prop-types";

function ImportDetailsRow({ label, value }) {
  return (
    <div className="text-left mb-1">
      <span className="font-semibold">{label}: </span>
      <span className="font-mono display-linebreak">{value}</span>
    </div>
  );
}

ImportDetailsRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default ImportDetailsRow;