import React from "react";
import axios from "axios";
import appConfig from "Config.js"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import DownloadCsv from "components/DataTable/DownloadCsv";
import PMGDataTable from "components/DataTable/PMGDataTable";
import { findMissingProperties } from "Utils";

library.add(faDownload);

const COLUMN_KEYS = [
  "uid",
  "lss",
  "age",
  "tenure",
  "wallet_share",
  "trajectory",
  "trajectory_likelihood",
  "retention_score",
  "mortgage_balance",
  "mortgage_term_remaining",
];

class CUDataTable extends React.Component{
  constructor(){
    super();
    
    this.state = {
      data: [],
      filteredItems: [],
      missingPropertyMap: {}, // Keeps track of missing columns (each key in the object is a column and the value is true if the column is missing and false otherwise)
      iframe: '',
      resetPaginationToggle: false,
      message: '',
      downloading: false,
      loading: false,
    };
  }

  componentDidMount() {          
    this.loadData(this.props.import_id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.import_id !== prevProps.import_id) {
      this.loadData(this.props.import_id);
    }
  }
  
  // Loads data for the table
  async loadData(import_id = "") {
    this.setState({ loading: true });

    try {
      const response = await axios.post(appConfig.baseURL + "api/get_json/" + import_id);

      this.setState({
        data: response.data,
        message: "",
        missingPropertyMap: findMissingProperties(response.data, COLUMN_KEYS),
      });
    } catch (error) {
      if (error.response?.data.message) {
        this.setState({ message: error.response.data.message });
      }
      console.error(error);
    } finally {
      this.setState({loading: false});
    }
  }

  // Loads the iframe
  loadIframe(uid = "") {
    // PlainTextUid=true disables decryption of UID (the UID is not encrypted in our web client)
    this.setState({iframe: `${appConfig.iframeURL}doxim/profile/${JSON.parse(localStorage.getItem("user")).doxim_key}?FocusId=${uid}&PlainTextFocusId=true&ImportId=${this.props.import_id}`})
  }
  closeIframe() {
    this.setState({iframe: ''})
  }

  render() {
    const columns = [
      { name: "Unique Identifier", selector: row => row.uid, sortable: true, omit: this.state.missingPropertyMap.uid },
      { name: "Life Stage Segment", selector: row => row.lss, sortable: true, omit: this.state.missingPropertyMap.lss },
      { name: "Age", selector: row => row.age, sortable: true, omit: this.state.missingPropertyMap.age },
      { name: "Tenure", selector: row => row.tenure, sortable: true, omit: this.state.missingPropertyMap.tenure },
      { name: "Estimated Share of Wallet", selector: row => row.wallet_share, sortable: true, omit: this.state.missingPropertyMap.wallet_share },
      { name: "Trajectory", selector: row => row.trajectory, sortable: true, omit: this.state.missingPropertyMap.trajectory },
      { name: "Trajectory Likelihood", selector: row => row.trajectory_likelihood, sortable: true, omit: this.state.missingPropertyMap.trajectory_likelihood },
      { name: "Retention Score", selector: row => row.retention_score, sortable: true, omit: this.state.missingPropertyMap.retention_score },
      { name: "Mortgage Balance", selector: row => row.mortgage_balance, sortable: true, omit: this.state.missingPropertyMap.mortgage_balance },
      { name: "Mortgage Term Remaining", selector: row => row.mortgage_term_remaining, sortable: true, omit: this.state.missingPropertyMap.mortgage_term_remaining }
    ];
     
    return (
      <div className="flex flex-wrap items-start justify-center">
        <div className="grid grid-cols-1 w-full">
          <div className="CuData">
            <div className="container md:mx-auto px-4">
              <PMGDataTable
                title={this.props.import_title}
                columns={columns}
                data={this.state.data}
                loading={this.state.loading}
                onRowClicked={rowData => {
                  this.loadIframe(rowData.uid);
                }}
                footerComponent={<DownloadCsv import_id={this.props.import_id}/>}
              />
            </div>
          </div>

          {this.state.iframe !== "" &&
            <div className={"Popup"} onClick={this.closeIframe.bind(this)}>
              <div className="Content">
                <button className="Close cursor-pointer" onClick={this.closeIframe.bind(this)}>
                  <img src="/close.png" alt="Popup close button"/>
                </button>
                <div className="ContentDivider"></div>
                <iframe title="iframe" src={this.state.iframe} />
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default CUDataTable;
