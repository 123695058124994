import React from "react";
import img_step_1 from 'assets/img/step-1.png';
import img_step_2 from 'assets/img/step-2.png';
import img_step_3 from 'assets/img/step-3.png';
import img_step_4 from 'assets/img/step-4.png';
import img_step_5 from 'assets/img/step-5.png';

class Instructions extends React.Component {
  render() {
    return (
      <div className="flex flex-wrap items-start justify-center">

      <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-2 p-5 md:w-3/4 lg:w-full">
        <div className="text">
          <strong>1. Upload .CSV file.</strong> Click the "Choose file" button and select your .CSV data file from your computer, then click the “open” button to upload the file.
          <br/><img src={img_step_1} alt="Step 1" className="py-5 justify-center m-auto max-w-full" />
        </div>
        <div className="text">
          <strong>2. Initiate segmentation process.</strong> Click the "Process" button to start the analysis and segmentation of your .CSV file.
          <br/><img src={img_step_2} alt="Step 2" className="py-5 justify-center m-auto max-w-full" />
        </div>
        <div className="text">
          <strong>3. Wait for analysis process to complete.</strong>
          <br/><img src={img_step_3} alt="Step 3" className="py-5 justify-center m-auto max-w-full" />
        </div>
        <div className="text">
          <strong>4. Review results.</strong> Your segmentation and predictive analytic results will appear below when complete for review.
          <br/><img src={img_step_4} alt="Step 4" className="py-5 justify-center m-auto max-w-full" />
        </div>
        <div className="text">
          <strong>5. Download new .CSV file.</strong> Click the “Download” button to save your new .CSV data file to your computer.
          <br/><img src={img_step_5} alt="Step 5" className="py-5 justify-center m-auto max-w-full" />
        </div>
      </div>
      
    </div>

    )
  }
}

export default Instructions;