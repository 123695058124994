import { useState } from "react";
import PropTypes from "prop-types";
import { formatDateTimeStr } from "Utils";
import ImportDetailsRow from "./ImportDetailsRow";
import Chevron from "components/Icons/Chevron";

function ImportError({ error, errorNumber }) {
  const [show, setShowError] = useState(false);

  return (
    <div>
      <div className="flex gap-1 items-center">
        <h1 className="text-left text-lg font-semibold">
          Error #{errorNumber}
        </h1>
        <button onClick={() => setShowError(!show)}>
          <Chevron className="w-5" direction={show ? "down" : "up"} />
        </button>
      </div>
      {show &&
        <div>
          <ImportDetailsRow label="Timestamp" value={formatDateTimeStr(error.timestamp)} />
          <ImportDetailsRow label="Message" value={error.message} />
          <ImportDetailsRow label="Stacktrace" value={error.stacktrace} />
        </div>
      }
    </div>
  );
}

ImportError.propTypes = {
  error: PropTypes.object.isRequired,
  errorNumber: PropTypes.number.isRequired
};

export default ImportError;