function GradientCircle({
  children,
  width,
  height,
  borderWidth,
  leftColor,
  rightColor,
  zIndex = 0,
}) {
  const widthNumber = parseInt(width, 10);
  const heightNumber = parseInt(height, 10);
  const borderWidthNumber = parseInt(borderWidth, 10);

  if (!widthNumber || !heightNumber || !borderWidthNumber) {
    throw new Error(
      `All dimensions must contain a number. You provided these dimensions: width = ${width}, height = ${height}, borderWidth = ${borderWidth}.`
    );
  }

  const widthUnit = typeof width === "string" ? width.replace(/\d/g, "") : "px";
  const heightUnit = typeof height === "string" ? height.replace(/\d/g, "") : "px";
  const borderWidthUnit = typeof borderWidth === "string" ? borderWidth.replace(/\d/g, "") : "px";

  const circleStyle = {
    backgroundImage: `linear-gradient(to right, ${leftColor}, ${rightColor})`,
    borderRadius: "50%",
    minWidth: `${widthNumber}${widthUnit}`,
    minHeight: `${heightNumber}${heightUnit}`,
    zIndex
  };

  const contentStyle = {
    position: "relative",
    top: `${borderWidthNumber}${borderWidthUnit}`,
    left: `${borderWidthNumber}${borderWidthUnit}`,
    width: `${widthNumber - borderWidthNumber * 2}${widthUnit}`,
    height: `${heightNumber - borderWidthNumber * 2}${heightUnit}`,
    background: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.25rem",
  };

  return (
    <div style={circleStyle}>
      <div style={contentStyle}>{children}</div>
    </div>
  );
}

export default GradientCircle;
