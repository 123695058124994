import { useMemo } from "react";
import { useLocation } from "react-router";

/**
 * Formats a date string and includes time.
 * @param {string} dateStr Date string with time to format.
 * @returns Datetime string formatted as `YYYY-MM-DD HH:MM:SS tt zzz` (for example, `2023-01-01 12:53:29 PM EST`).
 */
export function formatDateTimeStr(dateStr) {
  const dateTime = new Date(dateStr);

  const date = dateTime.toISOString().substring(0, 10);

  const time = dateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    hour12: true,
    minute: "2-digit",
    second: "2-digit",
  });

  const timeZone = dateTime.toLocaleTimeString("en-US", {
    timeZoneName: "short",
  }).split(" ")[2];

  return `${date} ${time} ${timeZone}`;
}

/**
 * Formats a date string (time is not included).
 * @param {string | Date} dateStr Date object or date string to format.
 * @returns Date string formatted as `YYYY-MM-DD` (for example, `2023-01-01`).
 */
export function formatDateStr(date) {
  const dateObj = typeof date === "string" ? new Date(date) : date;

  return dateObj.toISOString().substring(0, 10);
}

/**
 * Gets today's date.
 * @returns The current date as a string. The date will be formatted as `YYYY-MM-DD`.
 */
export function getCurrentDate() {
  return formatDateStr(new Date());
}

export function addDays(date, days) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}


/**
 * Generates a random password.
 * @param {number} length The password length.
 * @returns The password that was generated.
 */
export function generatePassword(length) {
  // List of characters retrieved from https://wmich.edu/arts-sciences/technology-password-tips
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~`!@#$%^&*()_-+={[}]|\\:;\"'<,>.?/"
  
  let password = "";
  for (let i = 0; i <= length; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
   }

   return password;
}

/**
 * A custom hook that builds on useLocation to parse a query string.
 * 
 * Source: https://v5.reactrouter.com/web/example/query-parameters
 * @returns Object with parsed query string.
 */
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Downloads a file to the user's local file system.
 * @param {any} data The data for the file.
 * @param {string} filename The name of the file.
 */
export function downloadFile(data, filename) {
  // Convert the Byte Data to BLOB object.
  const blob = new Blob([data], { type: "application/octetstream" });

  // Check the Browser type and download the File.
  const isIE = false || !!document.documentMode;
  if (isIE) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(link);
    a.remove();
  }
}

/**
 * Wraps a promise to make it cancelable.
 * from istarkov >> https://github.com/facebook/react/issues/5465#issuecomment-157888325
 * @param {Promise} promise The promise to wrap.
 * @returns A wrapped promise with a cancel method.
 */
export function makeCancelable(promise) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>{
      if (!hasCanceled_) resolve(val)
    });
    promise.catch((error) => {
      if (!hasCanceled_) reject(error)
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
}

/**
 * Finds missing properties for an array of objects. A property is considered missing if 
 * its value is `undefined`, `null`, or an empty string for all objects in the array.
 * @param {object[]} objArray The array of objects to check for missing properties.
 * @param {string[]} properties An array of property names to check for.
 * @returns {{[key: string]: boolean}}
 * An object where keys are property names, and values are `true` if the property is 
 * missing for all objects in the array, and `false` otherwise.
 */
export function findMissingProperties(objArray, properties) {
  const missingPropertyMap = {};

  properties.forEach(prop => {
    missingPropertyMap[prop] = objArray.every(obj => !obj.hasOwnProperty(prop))
  });

  return missingPropertyMap;
}
