import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Button from "components/Buttons/Button";

library.add(faCircleInfo);

export default function InfoButton({ onClick, className, tooltip, tooltipPosition }) {
  return (
    <Button 
      aria-label="info button"
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={onClick} 
      className={`${className} ml-1 py-0 px-0 relative !text-slate-600 hover:!text-slate-700 bg-transparent hover:bg-transparent`}>
      <FontAwesomeIcon icon="fa-solid fa-circle-info"/>
    </Button>
  );
}