import React from "react";
import DataTable from "react-data-table-component-with-filter";
import SectionLoader from "components/Loaders/SectionLoader";

// Defining styles like this instead of a .css file allows changing styles based on conditions
// Documentation for conditional row styling:
// https://react-data-table-component.netlify.app/?path=/docs/api-custom-conditional-formatting--page
const customStyles = {
  rows: {
    style: {
      cursor: "pointer",
      '&:hover': {
        textDecoration: "underline",
        background: "#fafafa",
      }
    }
  }
};

/*
  props.data example:
    [
      [field1, field2, field3], // Row of data
      [field1, field2, field3],
      [field1, field2, field3],
    ]
*/
class PMGDataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      data: props.data,
      filteredData: props.data,
      filterText: "",
      resetPaginationToggle: false,
      selectableRowsToggle: props.selectableRows ?? false,
    };
  }
  
  // Updates table when the data changes (data is passed in through props)
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.data !== prevProps.data) {
      this.setState({filteredData: this.props.data});
    }
  }

  handleChange(e) {
    if (this.state.filterText === "") {
      this.setState({ resetPaginationToggle: true });
    }

    // Loop through each object in the array
    const filteredData = [];
    const newFilterText = e.target.value;
    const lowerCaseFilterText = newFilterText.toLowerCase();
    for (const obj of this.props.data) {
      // Loop through each property in the object
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key]?.toString().toLowerCase();

          if (lowerCaseFilterText[0] === "=" && value === lowerCaseFilterText.substring(1)) {
            filteredData.push(obj);
            break;
          } else if (value?.includes(lowerCaseFilterText)) {
            filteredData.push(obj);
            break; // If a match is found in any property, move to the next object
          }
        }
      }
    }

    this.setState({
      filterText: newFilterText, // Update text in the search box
      filteredData,
    });
  }

  getSubHeaderComponent = () => {
    return (
      <div className="flex w-full">
        <h1 className="font-bold text-xl mt-2 -ml-2 mr-auto">{this.props.title}</h1>
        <input 
          type="text" 
          name="search" 
          placeholder="Search"
          onChange={e => this.handleChange(e)}
          value={this.state.filterText}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.loading?
        <div className="flex justify-center">
          <SectionLoader/>
        </div>
        :
        <>
          <DataTable
            columns={this.props.columns}
            data={this.state.filteredData}
            selectableRows={this.state.selectableRowsToggle}
            clearSelectedRows={this.props.clearSelectedRows}
            selectableRowsVisibleOnly={true}
            selectableRowDisabled={this.props.selectableRowDisabledHandler ? row => this.props.selectableRowDisabledHandler(row) : null}
            onSelectedRowsChange={({allSelected, selectedCount, selectedRows}) => {
              if (this.props.onSelectedRowsChange) {
                this.props.onSelectedRowsChange(allSelected, selectedCount, selectedRows)
              }
            }}
            persistTableHead
            pagination
            paginationResetDefaultPage={this.state.resetPaginationToggle}
            subHeader
            subHeaderComponent={this.getSubHeaderComponent()}
            onRowClicked={(rowData) => this.props.onRowClicked && this.props.onRowClicked(rowData)}
            customStyles={this.props.onRowClicked ? customStyles : {}}
          />
          
          {/* Display footer component if its been provided */}
          {this.props.footerComponent &&
          <>
            {this.props.footerComponent}
          </>
          }
        </>
        }
      </div>
    );
  }
}

export default PMGDataTable;