import BarChart from "components/Charts/BarChart";

function AverageCurrencyChart({ data }) {
  function formatCurrency(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
    const currencyFormmatter = new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits,
      maximumFractionDigits,
    });

    return currencyFormmatter.format(parseFloat(value))
  }

  function renderTooltip({ id, color, data }) {
    return  (
      <div className="flex gap-1 p-2 bg-white rounded-lg shadow-xl items-center">
        <div style={{background: `${color}`, width: "1rem", height: "1rem"}}/>
        {id} - 
        <span className="font-bold">{formatCurrency(data[id])}</span>
      </div>
    );
  }

  return (
    <BarChart
      title="Averages"
      data={data} 
      groupMode="stacked"
      keys={["HH Income", "HH Assets"]} 
      colors={{
        "HH Assets": "#3A82D4", 
        "HH Income": "#43B5E3"}}
      axisLeftTitle="Amount"
      axisLeftTickValues={6}
      axisLeftLabelFormat={value => formatCurrency(value, 0, 0)}
      axisLeftTitleOffset={(() => {
        // Prevent the title from getting cutoff by changing the offset based on label size
        const max = Math.max(data[0]["HH Assets"], data[1]["HH Income"]);
        
        let offset = -75;
        for (let threshold = 1000000; threshold <= Number.MAX_SAFE_INTEGER; threshold *= 10) {
          if (max < threshold) {
            return offset;
          }

          offset -= 5;
        }
      })()}
      height={250}
      margin={{ top: 5, right: 130, bottom: 90, left: 95 }}
      renderTooltip={renderTooltip}
      />
  );
}

export default AverageCurrencyChart;
