import { components } from "react-select";

// The react-select component has an extra border because of tailwind base styles.
// This component allows overwriting the extra border.
const SelectInputOverride = props => (
  <components.Input 
     {...props} 
     inputClassName="outline-none border-none shadow-none focus:ring-transparent"
  />
);

export default SelectInputOverride;