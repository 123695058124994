import React from "react";
import "style/App.css";

function SectionHeading({ children, noPadding }) {
  return (
    <div className={`container md:mx-auto ${!noPadding && "px-4"}`}>
      <h2 className="title w-full">{children}</h2>
    </div>
  );
}

export default SectionHeading;