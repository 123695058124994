import ModalDialog from "components/Dialogs/ModalDialog";
import SectionHeading from "components/SectionHeading";
import ImportError from "./ImportError";
import ImportDetailsRow from "./ImportDetailsRow";
import PropTypes from "prop-types";

function ImportDetailsDialog({ importRecord, open, onClose, columns }) {
  return (
    <ModalDialog
      title="Import details"
      open={open}
      positiveButtonText="Close"
      positiveButtonClassName="ok-btn"
      onPositiveButton={onClose}
      onClose={onClose}
      contentClassName="overflow-y-scroll h-96"
      size="xl">
      {importRecord &&
        <>
          {/* General information */}
          {importRecord.map((value, index) => (
            columns[index] && value && <ImportDetailsRow key={`import-general-info-${index}`} label={columns[index].name} value={value} />
          ))}

          {/* Error information */}
          <SectionHeading noPadding>Errors</SectionHeading>
          {importRecord[6].length > 0 ?
            <div className="flex flex-col gap-2">
              {importRecord[6].map((error, index) =>
                <ImportError key={`import-error-info-${index}`} error={error} errorNumber={index + 1} />
              )}
            </div>
            :
            <div>
              No error information available for this import. This likely occurred because the
              import was created before the system was updated to save errors to the database.
            </div>
          }
        </>
      }

    </ModalDialog>
  );
}

ImportDetailsDialog.propTypes = {
  importRecord: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object),
};

export default ImportDetailsDialog;