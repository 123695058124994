import HeadingContainer from "components/Iframe/HeadingContainer";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import FeedbackButtons from "./FeedbackButtons";

export default function IndustryEngagement({ data, doximKey, uid }) {
  return (
    <div className="col-12 col-md-6">
      <div className="widget">
        <div className={`inner lss ${data.code}`}>
          <div className="text-center">
            <h2 className="subtitle iframe-heading">
              <HeadingContainer className="justify-center">
                Industry Engagement
                <IframeInfoButton
                    tooltip="Based on the member's sub-segment, the average attitudes towards the financial services industry. 
                    Measures include how much value they place on financial advice, how much they trust the financial services 
                    industry, and their self-reported financial literacy."/>
                <FeedbackButtons doximKey={doximKey} uid={uid} widget="Industry Engagement"/>
              </HeadingContainer>
            </h2>
          </div>
          <div className="row">
            <div className='col-7'>
              <h5 className="iframe-heading">Perceived Value of Advice</h5>
            </div>
            <div className='col-5 pb-2 text-center'>
              {data.segment_bullets[0].map((value, index) => 
                <div key={index} className="bullet">
                  <div className="fill" style={{width: value}}></div>
                </div>
              )}
              <small>{data.segment_decription[0]}</small>
            </div>
          </div>
          <div className="row">
            <div className='col-7'>
              <h5 className="iframe-heading">Trust of Industry</h5>
            </div>
            <div className='col-5 pb-2 text-center'>
              {data.segment_bullets[1].map((value, index) => 
                <div key={index} className="bullet">
                  <div className="fill" style={{width: value}}></div>
                </div>
              )}
              <small>{data.segment_decription[1]}</small>
            </div>
          </div>
          <div className="row">
            <div className='col-7'>
              <h5 className="iframe-heading">Financial Literacy</h5>
            </div>
            <div className='col-5 pb-2 text-center'>
              {data.segment_bullets[2].map((value, index) => 
                <div key={index} className="bullet">
                  <div className="fill" style={{width: value}}></div>
                </div>
              )}
              <small>{data.segment_decription[2]}</small>
            </div>
          </div>
        </div>
      </div>
  </div>
  );
}