import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

library.add(faQuestion);

function QuestionMark() {
  return (
    <FontAwesomeIcon
      style={{ color: "#0054b7" }} // Same color as headings
      icon="fa-solid fa-question"
      data-testid="questionmark"
    />
  );
}

export default QuestionMark;