import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import appConfig from "Config";
import logout from "components/Login/Logout";

function SuperuserComponent({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    async function checkToken() {
      try {
        let response = await axios.post(`${appConfig.baseURL}api/token_check`);
        if (response?.data && response.data.superuser === false) {
          window.history.replaceState(null, "", "/");
          navigate("/");
          logout();
        }
      } catch (error) {
        console.error(error);
      }
    }

    checkToken();
  }, [navigate]);

  return (
    <>
      {children}
    </>
  );
}

export default SuperuserComponent;