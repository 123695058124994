import HeadingContainer from "components/Iframe/HeadingContainer";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import FeedbackButtons from "./FeedbackButtons";

export default function WalletShare({ walletShareData, lssData, doximKey, uid }) {
  return (
    <div className="col-6 col-md-3">
      <div className="widget">
        <div className="inner text-center">
          <h2 className="subtitle iframe-heading">
            <HeadingContainer className="justify-center">
              Estimated Share of Wallet
              <IframeInfoButton
                tooltip="The proportion of their household's investable assets the member holds within deposit accounts with the credit union."/>
              <FeedbackButtons doximKey={doximKey} uid={uid} widget="Wallet Share"/>
            </HeadingContainer>
          </h2>
          {(walletShareData.wallet_share !== undefined && walletShareData.wallet_share !== null) ?
            <>
              <h3 className="iframe-heading"><strong>{walletShareData.wallet_share}%</strong></h3>
              <h5 className="iframe-heading">Investable Assets</h5>
              {lssData?.code &&
                <h5 className="iframe-heading">Average {lssData.code} has {walletShareData.average}% with us</h5>
              }
            </>
          :
          <h3><strong>N/A</strong></h3>
          }
        </div>
      </div>
    </div>
  );
}