import HeadingContainer from "components/Iframe/HeadingContainer";
import FeedbackButtons from "components/Iframe/FeedbackButtons";
import IframeInfoButton from "components/Iframe/IframeInfoButton";

export default function Advice({ data, doximKey, uid }) {
  return (
    <div className="col-12 col-md-6">
      <div className="widget">
        <div className="inner">
          <h2 className="subtitle iframe-heading">
            <HeadingContainer className="justify-center">
              Advice Factors
              <IframeInfoButton
                tooltip="A list of advice factors specific to the member's life stage. When members receive these 
                  advice factors, they are apt to be more financially successful than those who have not received."/>
              <FeedbackButtons doximKey={doximKey} uid={uid} widget="Advice" />
            </HeadingContainer>
            <small>Key contributors to financial success</small>
          </h2>
          <ul>
            {data.map(advice =>
              <li key={advice}>{advice}</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}