import React from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

export default function Button({
  id,
  type,
  className,
  onClick,
  tooltip,
  tooltipPosition,
  tooltipDelayMs,
  "data-testid": dataTestId,
  "aria-label": ariaLabel,
  disabled,
  children
}) {
  // This can be replaced with useId() if we upgrade to React v18.0 (see here: https://reactjs.org/blog/2022/03/29/react-v18.html#useid)
  // Uninstall the uuid package if it gets replaced: npm uninstall uuid
  const tooltipId = uuidv4();

  return (
    <button
      disabled={disabled ?? false}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      id={id}
      type={type ?? "button"}
      data-tip
      data-for={tooltipId}
      className={`${disabled ? "disabled-button " : ""}${className ? `${className} ` : ""}py-2 px-5 rounded bg-blue-500 !text-white hover:bg-blue-600 cursor-pointer`}
      onClick={onClick}>

      {children}

      {tooltip &&
        <ReactTooltip id={tooltipId} role="tooltip" place={tooltipPosition ?? "bottom"} delayShow={tooltipDelayMs ?? 0}>
          <div className="w-52 text-left font-normal" style={{ fontFamily: "Poppins" }}>
            {tooltip}
          </div>
        </ReactTooltip>
      }
    </button>
  );
}