import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import appConfig from "Config";
import PageLoader from "components/Loaders/PageLoader";

class DownloadCsv extends React.Component {
  constructor() {
    super();

    this.state = {
      downloading: false,
    };
  }

  downloadFile = (complete = false) => {
    let params = {
      complete: complete
    }

    this.setState({downloading: true})

    axios
    .post(appConfig.baseURL + "api/download/" + this.props.import_id, params)
    .then((response) => {
      let filename = this.props.import_id + '.csv';

      //Convert the Byte Data to BLOB object.
      let blob = new Blob([response.data], { type: "application/octetstream" });

      //Check the Browser type and download the File.
      let isIE = false || !!document.documentMode;
      if (isIE) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blob);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = link;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(link);
      }

      this.setState({downloading: false})
    })
    .catch((errors) => {
      if (errors.response.data.message) {
        this.setState({ message: errors.response.data.message });
      }
      console.log(errors);
      this.setState({downloading: false})
    });
  }

  render() {
    return (
      <div>
      <div className="flex items-left gap-2">
        <button onClick={this.downloadFile.bind(this, false)}
          className="py-2 px-5 rounded bg-blue-500 text-white hover:bg-blue-600 cursor-pointer">
          <FontAwesomeIcon icon="download" /> Quick CSV
        </button>

        <button onClick={this.downloadFile.bind(this, true)}
          className="py-2 px-5 rounded bg-white-500 text-blue-500 hover:bg-blue-600 hover:text-white cursor-pointer">
          <FontAwesomeIcon icon="download" /> Complete CSV
        </button>
      </div>

      <PageLoader loading={this.state.downloading} />
      </div>
    );
  }
}

export default DownloadCsv;