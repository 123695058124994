import { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import PageLoader from "components/Loaders/PageLoader";
import SectionHeading from "components/SectionHeading";
import appConfig from "Config";
import CUForm from "components/Superuser/CUForm";

export default function CreateCreditUnion() {
  const [loading, setLoading] = useState(false);

  async function onSubmit(data, event, resetForm, setError) {
    event.preventDefault(); // Prevent form submission from reloading page

    setLoading(true);
    
    try {
      await axios.post(`${appConfig.baseURL}api/superuser/cu`, data);
      
      resetForm();

      toast.success("Credit union created");
    } catch (error) {
      toast.error("Credit union could not be created due to error(s)");

      error.response.data?.errors?.forEach(fieldError => {
        setError(fieldError.field, { message: fieldError.error });
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container px-4 pb-4 mx-auto">
      <PageLoader loading={loading}/>

      <SectionHeading>Create Credit Union</SectionHeading>
      <CUForm 
        submitButtonText="Create" 
        onSubmit={(data, e, resetForm, setError) => onSubmit(data, e, resetForm, setError)}
        createMode/>
    </div>
  );
}