import React, { createRef } from "react";
import ProgressBar from 'react-customizable-progressbar';
import axios from "axios";
import appConfig from "Config.js";
import Input from "components/UserInput/Input.js";
import Error from "components/Error.js";
import Button from "components/Buttons/Button.js";
import { getCurrentDate } from "Utils.js";
import {ProgressExtrapolator} from "ProgressExtrapolator.js"
import { IMPORT_STATES } from "constants/importStates";

const PERSISTENT_STATE = 'cu_file_upload_persistent';

class CUFileUpload extends React.Component{
  constructor(props) {
    super(props);

    this.persistentState = JSON.parse(localStorage.getItem(PERSISTENT_STATE)) || {};
    this.state = {
      importProcessingInitialRequestAborter: null,
      selectedFile: null,
      selectedFileError: '',
      progress: NaN,
      importId: props.import_id || null,
      fileSize: 0,
      message: '',
      importTitle: getCurrentDate(),
      titleError: false,
      ...this.persistentState,
    };

    this.fileRef = createRef();

    this.progressExtrapolator = null;

    if (props.import_processing && this.state.importId) {
      //resuming progress
      //need to start progress here instead of componentDidMount to prevent double render & error
      this.state.progress = 0;
    }
  }

  stopProgress() {
    if (this.progressExtrapolator != null) {
      this.progressExtrapolator.onProgress = null;
      this.progressExtrapolator.finish();
    }
    this.setState({progress: NaN})
  }

  componentDidMount() {
    if (this.props.import_processing && this.state.importId) {
      //resuming progress
      this.startProgress();
    }
  }

  componentWillUnmount() {
    this.stopProgress();
  }

  setStatePersistently(state) {
    Object.assign(this.persistentState, state);
    localStorage.setItem(PERSISTENT_STATE, JSON.stringify(this.persistentState));
    this.setState(state);
  }

  // On file selected
  onFileChange = event => { 
    const selectedFile = event.target.files[0];
    this.setState({ 
      selectedFile,
    }); 
    //save the size incase we need to resume progress and re-estimate progress duration
    this.setStatePersistently({
      fileSize: selectedFile.size,
    });

    const fileExtension = selectedFile.name.substring(event.target.files[0].name.lastIndexOf(".") + 1);
    if (selectedFile && !["csv", "zip"].includes(fileExtension)) {
      this.setState({ 
        selectedFileError: "The chosen file must be a CSV or ZIP",
        selectedFile: null,
      });
      this.setStatePersistently({
        fileSize: 0,
      });
    } else {
      this.setState({ selectedFileError: '' });
    }
  }; 

  onTitleChange = value => {
    this.setState({
      importTitle: value,
      titleError: false,
    });
  };
   
  onFileUpload = () => {   
    // A new abort controller is needed for each request
    const importProcessingInitialRequestAborter = new AbortController();

    const selectedFileError = this.state.selectedFile ? "" : "Please choose a file";
    const titleError = this.state.importTitle.trim() ? false : true;
    this.setState({
      importProcessingInitialRequestAborter,
      selectedFileError,
      titleError,
      message: ""
    });
    
    if (titleError || selectedFileError !== "") {
      return;
    }

    // Request made to the backend api
    let config = {
      signal: importProcessingInitialRequestAborter.signal
    }    
    let params = new FormData();    
    params.append( 
      "file",
      this.state.selectedFile, 
      this.state.selectedFile.name
    );
    params.append("title", this.state.importTitle);

    this.setState({progress: 0})
    axios
    .post(appConfig.baseURL + "api/data_process", params, config)
    .then((response) => {
      this.setState({importId: response.data.import_id})
      this.props.onFileUpload({
        importTitle: this.state.importTitle,
        importId: this.state.importId,
      });
      this.startProgress();
    })
    .catch((errors) => {
      //todo Change to checking errors.name === "CanceledError" after upgrading Axios to the latest version
      if (errors.message?.toLowerCase() === "canceled") {
        console.debug("File upload cancelled by user");
        return;
      }

      this.setState({progress: NaN})
      this.setState({
        message: errors.response?.data?.message || errors?.message || "Error processing file"
      });
      console.log(errors);
    })
  };

  //estimate progress with intermittent adjustments by polling server
  startProgress() {
    const user = JSON.parse(localStorage.getItem('user'));

    const checkRealCallback = async () => {
      try {
        const config = {
          params: { import_id: this.state.importId },
        };
        const res = await axios.get(appConfig.baseURL + "api/data_process_progress", config);

        switch (res.data.import_state) {
          case IMPORT_STATES.pending:
            return 0;
          case IMPORT_STATES.processing:
            return res.data.progress;
          case IMPORT_STATES.done:
          case IMPORT_STATES.canceled:
            this.done(true);
            return undefined;
          case IMPORT_STATES.processing_error:
            const errors = res.data.import_errors;
            this.done(
              false,
              !errors || errors.length===0 ? 
                "Error processing file" :
                errors.join("\n")
            );
            return undefined;
          default:
            console.error("Unknown import state:", res.data.import_state);
            return undefined;
        }
      } catch (error) {
        if (error?.response?.status === 404) {
          /*  if the import isn't in the db it's either a really weird issue and not the users fault,
              or it was deleted intentionally.
              either way this is almost certainly happening after reloading/revisiting the page,
              so it's best to just not show an error and let the user start fresh*/
          console.error('404 Not Found: import not found')
          this.done(false);
        } else {
          this.done(false, error?.response?.data?.message || "Error processing file");
        }
        return undefined;
      }
    };

    const onProgress = progress => {
      if (progress >= 100) {
        this.done(true);
      } else {
        this.setState({progress: progress});
      }
    };
    

    //todo: need to change this, for now polish parishes is just way faster, if it was comparable we wouldn't need to do anything weird like this
    let denominator = (user.cu_name === "Polish Parishes")? 1500 : 200;

    const timeMsGuess = (this.state.fileSize || 10000000) / denominator;
    this.progressExtrapolator = new ProgressExtrapolator(timeMsGuess, checkRealCallback, onProgress);
    this.progressExtrapolator.start();

    //force reconcile with low confidence to get more early samples incase of error
    this.progressExtrapolator.reconcile(true, true);
  }
  

  done(success, message='') {
    this.stopProgress();

    if (success) {
      this.setState({
        selectedFile: null,
        importId: this.state.importId,
        importTitle: getCurrentDate(),
      });
      //Clears the chosen file from the <input> element
      if (this.fileRef?.current) this.fileRef.current.value = ''; 
    }
    
    this.setState({message: message});

    this.props.onDone(success);
  }

  onCancel = () => {
    //stop the request if it hasn't finished starting
    this.state.importProcessingInitialRequestAborter.abort();

    this.done(false);

    //Clears the chosen file from the <input> element
    if (this.fileRef?.current) this.fileRef.current.value = '';

    this.setState({ importProcessingInitialRequestAborter: null });
    
    // There won't be an import ID if the file hasn't finished uploading, so we don't need to call cancel
    if (this.state.importId) {
      const formData = new FormData();
      formData.append("import_id", this.state.importId);

      axios
      .post(appConfig.baseURL + "api/data_process_cancel", formData)
      .catch((error) => {
        console.error(error);
      });
    }
  };

  render() {
    return (
      <div className="CuUpload flex justify-center">

        <div className="container px-4 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4">

          <div className={"mb-4 " + (Number.isFinite(this.state.progress)? "hidden" : "")}>
            <Input 
                labelText="Title"
                placeholder="Enter title for upload" 
                value={this.state.importTitle}
                onChange={this.onTitleChange}/>
            {this.state.titleError && 
              <Error>Please enter a title</Error>
            }
          </div>

          <br></br>
          <h5 className={(Number.isFinite(this.state.progress)? "hidden" : "")}>Select a CSV file to begin the data processing.</h5>

          <div className={"mt-2 form flex " + (Number.isFinite(this.state.progress)? "hidden" : "")}>
            <input data-testid="file-uploader" ref={this.fileRef} type="file" onChange={this.onFileChange} className="w-full mr-2 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
            <Button onClick={this.onFileUpload}>
              {(Number.isFinite(this.state.progress)? "Wait..." : "Process")}
            </Button>
          </div>
        
          <div style={{position: 'relative'}}>
            <ProgressBar className={"RCP " + (Number.isFinite(this.state.progress)? "flex" : "hidden")}
              progress={this.state.progress||0}
              radius={100}
              cut={120}
              rotate={-210}
              strokeWidth={28}
              strokeColor="#3881d5"
              strokeLinecap="butt"
              trackStrokeWidth={14}
              trackStrokeLinecap="butt"
            >
              <div className="indicator">
                <div>{(Math.round(this.state.progress) >= 100 ? "Saving data..." : "Transforming data... " + Math.round(this.state.progress||0) + "%")}</div>
              </div>
            </ProgressBar>

            <div className={(Number.isFinite(this.state.progress)? "" : "hidden")} style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '2' }}>
              <Button onClick={this.onCancel}>
                  Cancel
              </Button>
            </div>
          </div>

          <Error>{this.state.selectedFileError}</Error>
          <Error>{this.state.message}</Error>
        </div>
      </div>
    );
  }
}

export default CUFileUpload;
