import { useContext } from "react";
import { MemberProfileConfigurationContext } from "pages/Iframe";
import CheckMark from "components/Icons/CheckMark";
import XMark from "components/Icons/XMark";
import QuestionMark from "components/Icons/QuestionMark";
import fillerDiv from "components/Iframe/ProductSummaryAndOpportunities/ProductSummary/fillerDiv";

/** Products that should always be an X (never a question mark). */
const X_ONLY_PRODUCTS = [
  "online banking",
  "email",
];

/**
 * @param {object} props
 * @param {string} props.label
 * @param {boolean | number | string} props.value
 * @param {boolean} props.isRetentionAnchor
 * @param {boolean} props.isBold
 */
function ProductSummaryRow({ label, value, isRetentionAnchor, isBold = false }) {
  const configuration = useContext(MemberProfileConfigurationContext);

  const className = isBold ? "font-bold" : "";

  /**
   * Renders an icon based on the company's configuration and the product.
   * @param {string} productLabel The name of the product.
   * @param {boolean} hasProduct Indicates if the member has the product.
   */
  function renderIcon(productLabel, hasProduct) {
    if (hasProduct) {
      return <CheckMark />;
    }

    if (configuration.member_profile_question_mark_icon && !X_ONLY_PRODUCTS.includes(productLabel.toLowerCase())) {
      return <QuestionMark />;
    }

    return <XMark />;
  }

  return (
    <div className="product-row">
      {isRetentionAnchor ?
        <img className="w-5 h-5" src="/ret-score.png" alt="retention score"/>
      :
        <>{fillerDiv}</>
      }
      <span className={className}>
        {label}
      </span>
      <span className={`ml-auto ${className}`}>
        {typeof(value) === "boolean" ?
          renderIcon(label, value)
        :
          <>{value}</>
        }
      </span>
    </div>
  );
}

export default ProductSummaryRow;
