import "style/Iframe.css";

function Timestamp({ value }) {
  return (
    <div className="col-12">
      <span className="timestamp">Last update: {value}</span>
    </div>
  );
}

export default Timestamp;