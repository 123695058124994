import ProductSummaryRow from "components/Iframe/ProductSummaryAndOpportunities/ProductSummary/ProductSummaryRow";
import fillerDiv from "components/Iframe/ProductSummaryAndOpportunities/ProductSummary/fillerDiv";
import HeadingContainer from "components/Iframe/HeadingContainer";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import FeedbackButtons from "components/Iframe/FeedbackButtons";
import "style/Iframe.css";

function ProductSummary({ products, doximKey, uid }) {
  const filteredProducts = products?.filter(product => product.value !== null);

  return (
    <div className="flex flex-col gap-1">
      <div className="flex">
        {fillerDiv}
        <h2 className="text-left-override w-full">
          <HeadingContainer>
            Member Has
            <IframeInfoButton
              tooltip={
                <div>
                  Products and services held by the member within the credit union. The <img className="w-5 h-5 inline-block" src="/ret-score.png" alt="retention score"/> symbol 
                  to the left of some elements indicates holding this product or service has a positive impact on a member's retention score.
                </div>
              }/>
              <FeedbackButtons 
                doximKey={doximKey} 
                uid={uid} 
                widget="Product Summary"
                buttonContainerClassName="ml-auto"/>
          </HeadingContainer>
        </h2>
      </div>

      {filteredProducts?.length > 0 ?
      <>
        {filteredProducts.map((product, index) => (
          <ProductSummaryRow
            key={`product-summary-row-${index}`}
            label={product.label} 
            value={product.value} 
            isRetentionAnchor={product.is_retention_anchor}
            isBold={product.label === "Accounts"}/>
          ))
        }
      </>
      :
      <>N/A</>
      }

    </div>
  );
}

export default ProductSummary;