import React, { Component } from "react";
import { Outlet } from "react-router-dom";

class CreditUnion extends Component {
  constructor(props) {
    super(props);
    console.log("CreditUnion constructor");
  }
  
  render() {
    return (
      <>
        <div className="Dashboard text-center mt-10">
          <div className="flex flex-grow py-5"></div>
        </div>

        <Outlet />
      </>
    );
  }
}

export default CreditUnion;