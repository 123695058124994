import * as Sentry from "@sentry/browser";
import appConfig from "Config";
import axios from "axios";

/**
 * Removes login state/info from local storage, resets Sentry context, and removes JWT cookies.
 */
export default async function logout() {
  /*  
    todo: in the future consider checking a flag to see if we are logged in since 
      we can't check for the presence of an httponly cookie directly, but if we do that we should 
      be more stringent about a single source of truth for login state with 
      regards also to the user object and stuff, for now we just let the backend reject our logout with a 401

      one edge case is the user might clear local storage but not cookies, then click logout, 
      which if we used the user object as a login state flag would cause us to not call the logout route 
      leaving the cookie valid an on the users browser
  */

  localStorage.removeItem("user");
  localStorage.removeItem("org_info");
  Sentry.setUser(null);
  document.cookie = 'sentry_session_id=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

  // todo: maybe we should redirect to login page from this function? do we ever need to call this without redirecting?
  
  // JWTs are stored in an httponly cookie, so we need to call the backend to send a response to delete the cookies
  try {
    await axios.post(`${appConfig.baseURL}api/logout`);
  } catch (error) {
    console.log(error);
  }
}