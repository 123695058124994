import Button from "components/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faRotateRight);

function RefreshButton({ className, onClick, "aria-label": ariaLabel }) {
  return (
    <Button className={`${className ?? ""} px-2`} onClick={onClick} aria-label={ariaLabel}>
      <FontAwesomeIcon icon="fa-solid fa-rotate-right"/>
    </Button>
  );
}

export default RefreshButton;
