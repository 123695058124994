import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import PMGDataTable from "components/DataTable/PMGDataTable";
import PageLoader from "components/Loaders/PageLoader";
import SectionHeading from "components/SectionHeading";
import appConfig from "Config";
import CUForm from "components/Superuser/CUForm";
import CUPasswordForm from "components/Superuser/CUPasswordForm";
import Button from "components/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ModalDialog from "components/Dialogs/ModalDialog";

library.add(faTrashCan);

export default function ManageCreditUnions() {
  const [tableLoading, setTableLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const [editing, setEditing] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [selectedCu, setSelectedCu] = useState("");

  const [cuData, setCuData] = useState([]);

  // Refer to the map() call in useEffect() to get indexes for the row array
  const columns = [
    { name: "Username", selector: row => row[1], sortable: true }, 
    { name: "Name", selector: row => row[3], sortable: true }, 
    { name: "Shortname", selector: row => row[4], sortable: true }, 
    { name: "Doxim key", selector: row => row[5], sortable: true },
    { name: "Doxim secret", selector: row => row[6], sortable: true },
    { name: "Custom color", selector: row => row[7], sortable: true },
    { name: "Custom link", selector: row => row[8], sortable: true },
    // { name: "Custom brand", selector: row => row[9], sortable: true },
  ];

  useEffect(() => {
    async function fetchData() {
      setTableLoading(true);

      try {
        const response = await axios.get(`${appConfig.baseURL}api/superuser/cu`);
  
        const data = [];
        response.data?.forEach(cu => {
          data.push([
            cu._id.$oid,
            cu.username,
            cu.password,
            cu.name,
            cu.shortname,
            cu.doxim_key,
            cu.doxim_secret,
            cu.custom_color,
            cu.custom_link,
            cu.custom_brand
          ]);
        });

        setCuData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setTableLoading(false);
      }
    }

    fetchData();
  }, []);

  async function onSubmitCuForm(data, event, resetForm, setError) {
    try {
      setPageLoading(true);
      
      const response = await axios.patch(`${appConfig.baseURL}api/superuser/cu/${selectedCu[0]}`, data);
      
      if (response.status === 204) {
        toast.info("No values to change");
      }
      else {
        setDefaultValues({
          username: response.data.username,
          password: response.data.password,
          name: response.data.name,
          shortname: response.data.shortname,
          doximKey: response.data.doximKey,
          doximSecret: response.data.doximSecret,
          customColor: response.data.customColor,
          customLink: response.data.customLink,
          customBrand: response.data.customBrand,
        });
  
        // Update the table
        const newCuData = cuData.map(cu => {
          if (cu[0] === response.data._id.$oid) {
            return [
              response.data._id.$oid,
              response.data.username,
              response.data.password,
              response.data.name,
              response.data.shortname,
              response.data.doxim_key,
              response.data.doxim_secret,
              response.data.custom_color,
              response.data.custom_link,
              response.data.custom_brand
            ];
          }
  
          return cu;
        });
        setCuData(newCuData);
  
        toast.success("Credit union has been updated");
      }
    } catch (error) {
      console.log(error.response ?? error);

      error.response.data?.errors?.forEach(fieldError => {
        setError(fieldError.field, { message: fieldError.error });
      });
    } finally {
      setPageLoading(false);
    }
  }

  async function onSubmitPasswordForm(data, event, resetForm, setError) {
    try {
      setPageLoading(true);

      const cuId = selectedCu[0];
      await axios.patch(`${appConfig.baseURL}api/superuser/cu/password/${cuId}`, data);

      resetForm();

      toast.success("Password has been changed");
    } catch (error) {
      console.log(error.response ?? error);

      if (error.response.data) {
        setError(error.response.data.field, { message: error.response.data.error });
      }
    } finally {
      setPageLoading(false);
    }
  }

  function rowClickedHandler(rowData) {
    setDefaultValues({
      username: rowData[1],
      password: rowData[2],
      name: rowData[3],
      shortname: rowData[4],
      doximKey: rowData[5],
      doximSecret: rowData[6],
      customColor: rowData[7],
      customLink: rowData[8],
      customBrand: rowData[9],
    });
    
    setSelectedCu(rowData);
    setEditing(true);
  }

  function selectedRowsChangeHandler(allSelected, selectedCount, selectedRows) {
    setSelectedRows(selectedRows)
  }

  async function deleteCuHandler() {
    const config = {
      params: {
        cu_ids: selectedRows.map(row => row[0]).toString()
      }
    };

    try {
      const { data } = await axios.delete(`${appConfig.baseURL}api/superuser/cu`, config);

      toast.success("Credit union(s) deleted");
  
      // Remove deleted credit unions from the table
      const newCuData = cuData.filter(cu => !data.deleted_cu_ids.includes(cu[0]));
      setCuData(newCuData);

      return true;
    } catch (error) {
      console.log(error.response ?? error);

      toast.error(
        <div className="flex flex-col gap-2">
          Failed to delete credit union(s)
          <div>
            <b>Reason</b><br/>
            {error.response?.data?.message ?? ""}
          </div>
        </div>
      );

      return false;
    }
  }

  return (
    <div className="container px-4 pb-4 mx-auto">
      <SectionHeading>Select Credit Union</SectionHeading>
      <PMGDataTable
        title="Credit Unions"
        columns={columns}
        data={cuData}
        loading={tableLoading}
        selectableRows
        clearSelectedRows={clearSelectedRows}
        onSelectedRowsChange={selectedRowsChangeHandler}
        onRowClicked={rowClickedHandler}
        footerComponent={
          <Button onClick={() => {
            if (selectedRows.length > 0) {
              setOpenConfirmDeleteDialog(true);
            }
          }}>
            <FontAwesomeIcon icon="trash-can"/> Delete credit union(s)
          </Button>
        }
      />
      <ModalDialog
        title="Delete credit unions"
        open={openConfirmDeleteDialog}
        positiveButtonText="Yes"
        negativeButtonText="No"
        onPositiveButton={async () => {
          setOpenConfirmDeleteDialog(false);
          const deleted = await deleteCuHandler();

          // Clear the selected rows if deleting was successful
          if (deleted) {
            setClearSelectedRows(true);
            setSelectedRows([]); 
          }
        }}
        onNegativeButton={() => setOpenConfirmDeleteDialog(false)}
      >
        <div className="mb-2">Are you sure you want to delete the selected credit unions?</div>
        <span className="error-text font-bold">Warning:</span> This will delete the credit union and all its users.
      </ModalDialog>

      {editing &&
        <div>
          <PageLoader loading={pageLoading}/>

          <SectionHeading>Edit <i>{selectedCu[1]}</i></SectionHeading>

          <CUForm 
            submitButtonText="Update" 
            defaultValues={defaultValues}
            onSubmit={(data, event, resetForm, setError) => onSubmitCuForm(data, event, resetForm, setError)}/>
          
          <SectionHeading>Change password for <i>{selectedCu[1]}</i></SectionHeading>
          <CUPasswordForm onSubmit={(data, event, resetForm, setError) => onSubmitPasswordForm(data, event, resetForm, setError)}/>
        </div>
      }
    </div>
  );
}