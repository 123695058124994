import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';

export default function HeaderButtons({name = "Appointment"}) {
  return (
    <div className="row">
      <div className="flex items-center gap-3 p-0 relative left-[20px]" style={{justifyContent: "end"}}>
        <a href={"mailto:?subject="+name}>
          <img className="header-img" src="/mail.png" alt="email"/>
        </a>
        <button onClick={e => {
            e.preventDefault();
            atcb_action({
              name: name,
              startDate: "2022-10-14",
              endDate: "2022-10-18",
              options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
              timeZone: "Europe/Berlin",
              iCalFileName: "Reminder-Event",
            });
          }}>
            <img className="header-img" src="/calendar.png" alt="calendar"/>
        </button>
      </div>
    </div>
  );
}