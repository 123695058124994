export { IdleTransaction, Span, SpanStatus, TRACEPARENT_REGEXP, Transaction, extractTraceparentData, getActiveTransaction, hasTracingEnabled, spanStatusfromHttpCode, startIdleTransaction, stripUrlQueryAndFragment } from '@sentry/core';
import { addExtensionMethods } from './extensions.js';
export { addExtensionMethods } from './extensions.js';
import * as index from './node/integrations/index.js';
export { index as Integrations };
export { BROWSER_TRACING_INTEGRATION_ID, BrowserTracing } from './browser/browsertracing.js';
export { defaultRequestInstrumentationOptions, instrumentOutgoingRequests } from './browser/request.js';

// Treeshakable guard to remove all code related to tracing

// Guard for tree
if (typeof __SENTRY_TRACING__ === 'undefined' || __SENTRY_TRACING__) {
  // We are patching the global object with our hub extension methods
  addExtensionMethods();
}
