import HeadingContainer from "components/Iframe/HeadingContainer";
import ProductSummary from "components/Iframe/ProductSummaryAndOpportunities/ProductSummary";
import EngagementOpportunities from "components/Iframe/ProductSummaryAndOpportunities/EngagementOpportunities";

function ProductSummaryAndOpportunities({ products, engagementOpportunities, doximKey, uid }) {
  return (
    <div className="col-12 col-md-8 text-sm">
      <div className="widget">
        <div className="inner text-center">
          <h2 className="subtitle iframe-heading">
            <HeadingContainer className="justify-center">
              Product Summary and Opportunities
            </HeadingContainer>
          </h2>
          <div className="row">
            <div className="flex justify-center gap-6 product-summary-and-opportunities-content">
              <div className="w-2/5">
                <ProductSummary products={products} doximKey={doximKey} uid={uid} />
              </div>
              <div className="w-1/2">
                <EngagementOpportunities opportunities={engagementOpportunities} doximKey={doximKey} uid={uid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSummaryAndOpportunities;
