import HeadingContainer from "components/Iframe/HeadingContainer";
import HeaderButtons from "components/Iframe/HeaderButtons";
import GradientCircle from "components/Iframe/GradientCircle";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import "style/Iframe.css";

export default function Header({ data, trajectory, tenure }) {
  return (
    <>
      {data &&
        <div className="col-12">
          <div className="widget header">
            <div className="flex">
              <GradientCircle width={100} height={100} borderWidth={5} leftColor="#CBD366" rightColor="#3F85D6" zIndex={100}>
                <h2 className="circle-lss">{data.code}</h2>
                {trajectory && trajectory !== "N/A" && 
                  <img className="relative -top-2" src={`/trajectory_${trajectory}.png`} alt="trajectory arrow" width={30}/>
                }
              </GradientCircle>
              <div className="banner master">
                <div className="relative left-5 self-center">
                  <HeadingContainer>
                    <h2 className="font-bold iframe-heading">
                      <strong>
                        {data.code_ext}: {data.title}
                      </strong>
                    </h2>
                    <IframeInfoButton
                      tooltip="The sub-segment the member is most likely to fall into based on the data provided. Members are first divided into
                        life stages (age groups) and then further categorized into their sub-segments to allow estimations to be made regarding 
                        their current behaviours and needs."/>    
                  </HeadingContainer>
                  <HeadingContainer>
                    {tenure !== null &&
                      <>
                        <h6 className="font-bold iframe-heading" style={{marginBottom: "0px", marginTop: "2px"}}>
                          <span>Member for</span> {tenure} year(s)
                        </h6>
                        <IframeInfoButton
                          tooltip="How long the member has been with the credit union, based on the start date provided."
                          tooltipPosition="left"/>
                      </>
                    }
                  </HeadingContainer>
                </div>

                <HeaderButtons name={data.code_ext+": "+data.title} />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}