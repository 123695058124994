import { useFormContext } from "react-hook-form";

let idCounter = 0;

function Checkbox({ name, label, onChange, formOptions }) {
  const formContext = useFormContext();

  idCounter++;

  function handleOnChange(e) {
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <div className="flex gap-1 items-center">
      <input
        id={`checkbox_${idCounter}`}
        name={name}
        type="checkbox" 
        {...formContext?.register(name, formOptions)}
        onChange={handleOnChange}/>
      <label htmlFor={`checkbox_${idCounter}`}>
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
