import HeadingContainer from "components/Iframe/HeadingContainer";
import IframeInfoButton from "components/Iframe/IframeInfoButton";
import FeedbackButtons from "./FeedbackButtons";

export default function Retention({ data, doximKey, uid }) {
  return (
    <div className="col-12 col-md-4">
      <div className="widget">
        <div className="inner text-center">
          <h2 className="subtitle iframe-heading">
            <HeadingContainer className="justify-center">
              <img className="w-10 h-10 mr-1" src="/ret-score.png" alt="engagement score"/>
              Engagement Score
              <IframeInfoButton
                tooltip="On a scale from -50 to +50, a measure of the member's likelihood to continue doing
                business with the credit union (the likelihood of NOT closing their account)."/>
              <FeedbackButtons doximKey={doximKey} uid={uid} widget="Retention"/>
            </HeadingContainer>
          </h2>
          <div className="flex flex-col items-center gap-1">
            <div className="col-10">
              {data.retention && data.retention !== "N/A" ?
                <div className="graphic">
                  <div className="slider">
                    <div className="middle">
                      <h3 className={`score iframe-heading ${data.retention < -20 ? "red" : data.retention > 20 ? "green" : "yellow"}`}>
                        {data.retention}
                      </h3>
                    </div>
                    <div className="bullet" style={{left: `${data.percent}%`}}></div>
                    <span className="l1">-50<br/><small>Likely at risk</small></span>
                    <span className="l2">+50<br/><small>Engaged</small></span>
                  </div>
                </div>
              :
                <>N/A</>
              }
            </div>
            <br/>
            <div className="col-12 col-md-7">
              {data.average?.ratio && 
                <h5 className="iframe-heading"><strong>{data.average.ratio}</strong> are open to switching financial institutions</h5>
              }
            </div>
            {data.average?.topics && 
              <div className="flex flex-col w-fit text-center">
                <h5 className="iframe-heading">Considerations for switching:</h5>
                <ul>
                  {data.average.topics.map((value, index) => 
                    <li key={index} className="text-left">{value}</li>
                  )}
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    </div>                
  );
}