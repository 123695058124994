import appConfig from "Config.js";
import logout from "components/Login/Logout.js";

let refreshPromise;
let tokenVersion = 0;

// Essentially meta routes from the perspective of this interceptor; should not be intercepted for token refreshing. 
const authRoutes = [
  "/api/auth",
  "/api/refresh",
  "/api/logout",
];

/**
 * Attaches a response interceptor that automatically refreshes access tokens with 
 * refresh tokens, and prompts the user to login again if the refresh token is also expired.
 * @param {AxiosInstance} axios To attach interceptor to.
 */
export default function attachResponseInterceptor(axios) {
  // attach the token version to the request config, so that if the request fails due to expired token we can tell if the token has been refreshed since the request was made
  axios.interceptors.request.use((config) => {
    // not needed for auth/meta routes, but it's better to avoid the branch here and just attach anyway(those routes are rare)
    config.tokenVersion = tokenVersion;
    return config;
  });

  axios.interceptors.response.use(undefined, (err) => {
    let { config, response } = err;

    if (
      !config || 
      response?.status !== 401 || // not a 401
      authRoutes.includes(new URL(config.url).pathname) // don't try to refresh if auth/meta route
    ) {
      //do nothing
      return Promise.reject(err);
    }

    if (!response?.data?.msg?.includes("Token has expired")) {
      // 401 Unauthorized but not because of an expired token
      logout();
      window.location.href = "/"; // redirect to login page
      return Promise.reject(err);
    }

    //if the token has been refreshed since the request was made, skip to retry
    if (config.tokenVersion !== tokenVersion){
      //retry with new access token
      return axios(config);
    }
    
    if (!refreshPromise) {
      console.debug("config.url: " + config.url + " config.tokenVersion: " + config.tokenVersion + " tokenVersion: " + tokenVersion + " refreshPromise: " + refreshPromise + " REFRESHING");
      refreshPromise = axios
        .post(appConfig.baseURL + "api/refresh")
        .then(() => tokenVersion++)
        .catch(() => {})
        .finally(() => refreshPromise = null);
    }

    //retry with new token
    return refreshPromise.then(() => {
      if (config.tokenVersion === tokenVersion) {
        //token could not be refreshed
        logout();
        window.location.href = "/"; // redirect to login page
        return Promise.reject(err);
      } else {
        //retry with new access token
        return axios(config);
      }
    });
    
  });
}