import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from "components/Buttons/Button";

library.add(faCopy);

function CopyButton({ value, onCopy, "aria-label": ariaLabel }) {
  function copyHandler() {
    navigator.clipboard.writeText(value);

    if (onCopy) {
      onCopy(value);
    }
  }

  return (
    <Button className="px-2" onClick={copyHandler} aria-label={ariaLabel}>
      <FontAwesomeIcon icon="fa-solid fa-copy"/>
    </Button>
  );
}

export default CopyButton;
