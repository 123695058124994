import { useEffect, useState } from "react";
import Select from "react-select";
import Button from "components/Buttons/Button";
import SelectInputOverride from "components/UserInput/SelectInputOverride";

function UploadSelector({ imports, onCalculateHandler }) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (imports && imports.length > 0) {
      const options = buildSelectOptions(imports);
      setOptions(options);

      setSelectedOptions([options[0]]); // Select the first option by default
    }
  }, [imports]);

  function buildSelectOptions(imports) {
    const options = [];

    imports.forEach(imp => {
      options.push({
        value: imp[0],
        label: imp[1],
      });
    });

    return options;
  }

  function selectOnChangeHandler(newSelectedOptions) {
    setSelectedOptions(newSelectedOptions);
  }

  function onClick() {
    onCalculateHandler(selectedOptions.map(option => [option.value]));
  }

  return (
    <div className="flex gap-1 items-center justify-center w-full" >
      <Select 
        className="w-1/3"
        isMulti
        isClearable
        formatOptionLabel={data => 
          <div className="text-left">{data.label}</div>
        }
        options={options}
        value={selectedOptions}
        onChange={selectOnChangeHandler}
        noOptionsMessage={() => <>No uploads</>}
        components={{
          Input: SelectInputOverride,
        }}/>
      <Button onClick={onClick}>
        Calculate
      </Button>
    </div>
  );
}

export default UploadSelector;
