import React from "react";

class Brand extends React.Component {
  render() {
    const orgInfo = JSON.parse(localStorage.getItem("org_info"));
    const customBrand = orgInfo?.custom_brand;
    const userName = orgInfo?.name;

    return (
      <div>
        {
          customBrand ? 
          <img
            src={"data:image/png;charset=utf-8;base64," + customBrand}
            alt={userName}
            className="flex self-center justify-self-center m-auto max-w-xs"/> 
            : 
            <h1 className="font-bold leading-tight text-5xl mt-0 mb-2">
              {userName}
            </h1>
        }
      </div>
    )
  }
}

export default Brand;