import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import appConfig from "Config";
import SectionLoader from "components/Loaders/SectionLoader";
import UploadSelector from "components/Dashboard/UploadSelector";
import PieChart from "components/Charts/PieChart";
import ChartContainer from "components/Charts/ChartContainer";
import BooleanPercentageChart from "components/Charts/BooleanPercentageChart";
import PercentageChart from "components/Charts/PercentageChart";
import AverageCurrencyChart from "components/Charts/AverageCurrencyChart";
import ExportReportForm from "components/Dashboard/ExportReportForm";
import Button from "components/Buttons/Button";
import { IMPORT_STATES } from "constants/importStates";
import "style/ModalDialog.css";

// The index in this array determines the order the segments are displayed in the pie chart
const LSS_MAP = [
  {
    lss: "D1",
    fullName: "Developing: Engagers",
    color: "#76BAF2",
  },
  {
    lss: "D2",
    fullName: "Developing: Passives",
    color: "#318CDD",
  },
  {
    lss: "D3",
    fullName: "Developing: Strivers",
    color: "#00529B",
  },
  {
    lss: "E1",
    fullName: "Establishing: Engagers",
    color: "#61C8B9",
  },
  {
    lss: "E2",
    fullName: "Establishing: Passives",
    color: "#479186",
  },
  {
    lss: "E3",
    fullName: "Establishing: Adjusters",
    color: "#006857",
  },
  {
    lss: "T1",
    fullName: "Transitioning: Engagers",
    color: "#F2F763",
  },
  {
    lss: "T2",
    fullName: "Transitioning: Critical Adjusters",
    color: "#D2D655",
  },
  {
    lss: "T3",
    fullName: "Transitioning: Adjusters",
    color: "#757830",
  },
  {
    lss: "M1",
    fullName: "Maturing: Engagers",
    color: "#B9F797",
  },
  {
    lss: "M2",
    fullName: "Maturing: Passives",
    color: "#A0D683",
  },
  {
    lss: "R1",
    fullName: "Realization: Achievers",
    color: "#C7C7C7",
  },
  {
    lss: "R2",
    fullName: "Realization: Survivors",
    color: "#787878",
  },
  {
    lss: "N/A",
    fullName: "N/A",
    color: "#042231",
  },
];

function DashboardStatistics({ imports }) {
  const [loading, setLoading] = useState(false);

  const [importIds, setImportIds] = useState("");
  const [filteredImports, setFilteredImports] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState({
    homeownership: [],
    advisor: [],
    registeredAccounts: [],
    maritalStatus: [],
    children: [],
    financialPlan: [],
    autoDeposit: [],
    autoWithdrawal: [],
    mortgage: [],
    trajectory: [],
    averages: [],
  });

  const [openReportForm, setOpenReportForm] = useState(false);

  const buildBarChartData = useCallback((percentages, averages) => {
    return {
      homeownership: {
        title: "Homeownership",
        data: buildBooleanPercentage(percentages.has_homeownership, "has_homeownership"),
      },
      advisor: {
        title: "Advisor",
        data: percentages.advisor && buildBooleanPercentage(percentages.has_advisor, "has_advisor"),
      },
      registeredAccounts: {
        title: "Registered Accounts",
        data: buildBooleanPercentage(percentages.has_registered_accounts, "has_registered_accounts"),
      },
      maritalStatus: {
        title: "Married",
        data: percentages.marital_status && buildBooleanPercentage(percentages.marital_status, "marital_status"),
      },
      children: {
        title: "Children",
        data: percentages.has_children && buildBooleanPercentage(percentages.has_children, "has_children"),
      },
      financialPlan: {
        title: "Financial Plan",
        data: percentages.has_financial_plan && buildBooleanPercentage(percentages.has_financial_plan, "has_financial_plan"),
      },
      autoDeposit: {
        title: "Auto Deposit",
        data: buildBooleanPercentage(percentages.auto_deposit, "auto_deposit"),
      },
      autoWithdrawal: {
        title: "Auto Withdrawal",
        data: buildBooleanPercentage(percentages.auto_withdrawal, "auto_withdrawal"),
      },
      mortgage: {
        title: "Mortgage",
        data: buildBooleanPercentage(percentages.mortgage_count, "mortgage_count"),
      },
      trajectory: percentages.trajectory && [
        {
          "id": "Straight",
          "Straight": percentages.trajectory.straight ?? 0
        },
        {
          "id": "Upward",
          "Upward": percentages.trajectory.upward ?? 0
        },
        {
          "id": "Downward",
          "Downward": percentages.trajectory.downward ?? 0
        },
        {
          "id": "N/A",
          "N/A": percentages.trajectory["N/A"] ?? 0
        },
      ],
      averages: [
        {
          "id": "HH Assets",
          "HH Assets": averages.hh_assets_value,
        },
        {
          "id": "HH Income",
          "HH Income": averages.hh_income_value,
        },
      ]
    };
  }, []);

  const buildPieChartData = useCallback(lssPercentages => {
    const pieData = Object.keys(lssPercentages).map(key => {
      const index = LSS_MAP.findIndex(value => value.lss === key);

      return {
        "id": key,
        "lss": LSS_MAP[index].fullName,
        "color": LSS_MAP[index].color,
        "tooltipColor": LSS_MAP[index].color, // Tooltip color is used so the original color can be used when opacity is reduced
        "value": lssPercentages[key],
        "position": index,
      };
    });

    return pieData.sort((a, b) => a.position - b.position);
  }, []);

  const getChartData = useCallback(async (importIds, lss = null) => {
    const config = {
      params: {
        import_ids: importIds,
        lss: lss
      }
    };

    const { data, status } = await axios.get(`${appConfig.baseURL}api/dashboard/charts`, config);
    
    if (status === 200) {
      setBarData(buildBarChartData(data.percentages, data.averages));

      let pieData = buildPieChartData(data.percentages.lss);
      // Reduce opacity for all slices except the clicked slice when a segment is clicked
      // Solution for changing opacity for hex colour adapted from here: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
      if (lss !== null) {
        pieData = pieData.map(data => {
          if (data.id !== lss) {
            return {
              ...data,
              color: `${!data.color.endsWith("1A") ? `${data.color}1A` : data.color}`,
            };
          } else {
            return data;
          }
        });
      }

      setPieData(pieData);
    }
  }, [buildBarChartData, buildPieChartData]);

  useEffect(() => {
    async function initStats() {
      try {
        setLoading(true);
    
        // Filter out uploads that are still processing
        const filteredImports = imports.filter(imp => imp[2] === IMPORT_STATES.done);
  
        // Don't do anything if there's no uploads available
        if (filteredImports.length === 0) {
          return;
        }
  
        const importIds = buildImportIdsParam([filteredImports[0]]);
    
        setImportIds(importIds);             // Import IDs are used to update bar chart data when a pie slice is clicked
        setFilteredImports(filteredImports); // Store imports data so it can be passed to the upload selector
        
        await getChartData(importIds);
      } catch (error) {
        console.error(error.response ?? error);
      } finally {
        setLoading(false);
      }
    }

    async function fetchData() {
      if (imports.length > 0) {
        await initStats();
      }
    }

    fetchData();
  }, [imports, getChartData]);

  async function onCalculateHandler(selectedOptions) {
    try {
      if (selectedOptions.length > 0) {
        setLoading(true);

        const importIds = buildImportIdsParam(selectedOptions);

        // Save import IDs in state so data can be updated when a pie slice is clicked
        setImportIds(importIds);

        await getChartData(importIds, null);
      }
    } catch (error) {
      console.log(error.response ?? error);
    } finally {
      setLoading(false);
    }
  }

  function buildImportIdsParam(imports) {
    let importIdsParamValue = "";

    for (let i = 0; i < imports.length; i++) {
      importIdsParamValue += imports[i][0];

      if (i !== imports.length - 1) {
        importIdsParamValue += ",";
      }
    }

    return importIdsParamValue;
  }

  function buildBooleanPercentage(data) {
    return [
      {
        "id": "Yes",
        "Yes": data.yes ?? 0,
      },
      {
        "id": "No",
        "No": data.no ?? 0,
      }
    ];
  }

  async function pieChartClickHandler(data, event) {
    try {
      setLoading(true);

      // Get chart data filtered by LSS
      await getChartData(importIds, data.id);
 
      // Show reset button when the data is filtered by LSS
      setShowReset(true);
    } catch (error) {
      console.log(error.response ?? error);
    } finally {
      setLoading(false);
    }
  }

  async function resetClickHandler() {
    try {
      setLoading(true);

      // Reset the data
      await getChartData(importIds);
      
      // Hide the reset button
      setShowReset(false);
    } catch (error) {
      console.log(error.response ?? error); 
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="container md:mx-auto px-4">
      <div className="flex flex-col gap-1 items-center justify-center mt-6">
        Select uploads to display summary information:
        <UploadSelector imports={filteredImports} onCalculateHandler={onCalculateHandler}/>
      </div>

      <div className="mt-4">
        {loading ?
          <div className="flex justify-center">
            <SectionLoader/>
          </div>
        :
          <>
            {pieData.length > 0 &&
              <ChartContainer>
                <div className="flex">
                  <PieChart
                    title="Life Stage Segments"
                    data={pieData}
                    onSliceClick={pieChartClickHandler}
                    showReset={showReset}
                    onResetClick={resetClickHandler}/>
                </div>
                <div>
                  <div className="flex flex-wrap content-center">
                    {Object.keys(barData).map(key => (
                      <React.Fragment key={`${key}-chart`}>
                        {key !== "trajectory" && barData[key].data && 
                          <BooleanPercentageChart title={barData[key].title} data={barData[key].data} />
                        }
                      </React.Fragment>
                    ))}
                    {barData.trajectory &&
                      <PercentageChart
                        title="Trajectory"
                        data={barData.trajectory}
                        groupMode="stacked"
                        renderLabel={({ value }) => `${value}%`}
                        keys={["N/A", "Downward", "Upward", "Straight"]}
                        colors={{
                          "Straight": "#00529B",
                          "Upward": "#3A82D4",
                          "Downward": "#43B5E3",
                          "N/A": "#71C2E2",
                        }}
                      />
                    }
                    <AverageCurrencyChart data={barData.averages}/>
                  </div>

                  <ExportReportForm
                    open={openReportForm}
                    onCloseCallback={() => setOpenReportForm(false)}/>
                  <div className="flex justify-end 2xl:mr-20 xl:mr-60">
                    <Button onClick={() => setOpenReportForm(true)}>
                      Export Report
                    </Button>
                  </div>
                </div>
              </ChartContainer>
            }
          </>
        }
      </div>
    </div>
  );
}

export default DashboardStatistics;
