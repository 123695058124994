// States for imports
export const IMPORT_STATES = {
  pending: "pending",
  processing: "processing",
  done: "done",
  processing_error: "processing_error",
  canceled: "canceled",
};

// State map for imports. It maps internal values to user friendly values.
export const IMPORT_STATE_MAP = {
  [IMPORT_STATES.pending]: "Pending",
  [IMPORT_STATES.processing]: "Processing",
  [IMPORT_STATES.done]: "Done",
  [IMPORT_STATES.processing_error]: "Processing Error",
  [IMPORT_STATES.canceled]: "Canceled",
};