import { Bar } from "@nivo/bar";

function BarChart({ 
      title, 
      data, 
      keys = [""],
      colors,
      groupMode = "grouped",
      renderTooltip,
      renderLabel,
      width = 425,
      height = 175,
      margin = { top: 5, right: 130, bottom: 30, left: 60 },
      minValue,
      maxValue,
      axisLeftTitle,
      axisLeftLabelFormat,
      axisLeftTickValues,
      axisLeftTitleOffset = -50,
      axisBottom,
    }) 
  {

  return (
    <div>
      {/* relative right-6 adjusts title so that it's in the middle of the chart */}
      <h1 className="text-lg w-fit mx-auto relative right-6">{title}</h1>
      <Bar
        width={width}
        height={height}
        data={data}
        keys={keys}
        indexBy="id"
        margin={margin}
        padding={0.3}
        groupMode={groupMode}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={({ id }) => colors[id]}
        enableLabel={false}
        borderColor={{
          from: "color",
          modifiers: [
            [
              "darker",
              1.6
            ]
          ]
        }}
        label={renderLabel}
        axisBottom={axisBottom}
        tooltip={renderTooltip}
        axisRight={null}
        enableGridY={true}
        maxValue={maxValue}
        minValue={minValue}
        axisLeft={{
          tickSize: 4,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: axisLeftTickValues,
          legend: axisLeftTitle,
          legendOffset: axisLeftTitleOffset,
          legendPosition: "middle",
          format: axisLeftLabelFormat
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="#FFFFFF"
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </div>
  );
}

export default BarChart;