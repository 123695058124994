import axios from "axios";
import React from "react";
import appConfig from "Config";
import CUDataTable from "components/DataTable/CUDataTable";
import Brand from "components/Brand";
import SectionHeading from "components/SectionHeading";
import PMGDataTable from "components/DataTable/PMGDataTable";
import { addDays, formatDateStr, formatDateTimeStr } from "Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import ModalDialog from "components/Dialogs/ModalDialog";
import Button from "components/Buttons/Button";
import InfoButton from "components/Buttons/InfoButton";
import DashboardStatistics from "components/Dashboard/DashboardStatistics";
import "style/ModalDialog.css";
import { IMPORT_STATES, IMPORT_STATE_MAP } from "constants/importStates";

library.add(faTrashCan);

const DELETE_THRESHOLD = 7;

class Dashboard extends React.Component{
  constructor(){
    super();

    this.columns = [
      { name: "Title", selector: (row) => row[1], sortable: true },
      { name: "Status", selector: (row) => IMPORT_STATE_MAP[row[2]], sortable: true},
      { name: "Timestamp", selector: (row) => formatDateTimeStr(row[3]), sortable: true },
    ]

    this.state = {
      imports: [],
      selectedImportId: null,
      selectedImportTitle: "",
      selectedRows: [],
      clearSelectedRows: false,
      filterText: "",
      resetPaginationToggle: false,
      message: "",
      loading: false,
      openConfirmDialog: false,
      openDeleteUploadsInfo: false,
    };
  }

  async componentDidMount() {
    this.setState({loading: true});
    
    try {
      const imports = await this.getImports();

      // Use the date for a title if the import doesn't have a title
      const importsWithDefaultTitle = imports?.map(imp => {
        imp[1] = imp[1] ?? formatDateStr(imp[3]);
        return imp;
      });

      // Get the first finished import so it can be displayed when the page loads
      const selectedImport = imports.find(imp => imp[2] === IMPORT_STATES.done);

      this.setState({
        imports: importsWithDefaultTitle,
        selectedImportId: selectedImport?.[0],
        selectedImportTitle: selectedImport?.[1],
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({loading: false});
    }
  }


  /**
   * Gets all imports for the credit union.
   * @returns The imports sorted from newest to oldest or an empty array if there are no imports.
   */
  async getImports() {
    const response = await axios.get(appConfig.baseURL + `api/get_imports`);

    const imports = response.data;
    if (imports?.length > 0) {
      return imports.sort((a, b) => new Date(b[3]) - new Date(a[3]));
    }
    return [];
  }

  onSelectedRowsChange(allSelected, selectedCount, selectedRows) {
    this.setState({
      selectedRows: selectedRows,
      clearSelectedRows: false,
    });
  }

  // Disables selecting the row when processing is not finished or when the import is done but past the delete threshold
  selectableRowDisabledHandler(row) {
    return row[2] === IMPORT_STATES.processing || (row[2] === IMPORT_STATES.done && Date.parse(row[3]) < (addDays(new Date(), -DELETE_THRESHOLD).getTime()));
  }

  deleteUploadsHandler() {
    // Don't need to do anything if no rows are selected
    if (this.state.selectedRows.length < 1) {
      return;
    }

    // Ask the user to confirm the deletion
    this.setState({openConfirmDialog: true});
  }

  deleteUploadsInfoHandler() {
    this.setState({openDeleteUploadsInfo: true});
  }

  async deleteUploads() {
    this.setState({
      loading: true,
      openConfirmDialog: false,
    });

    const import_ids = [];
    this.state.selectedRows.forEach(row => import_ids.push(row[0]));

    const config = {
      data: {
        import_ids
      },
    }

    try {
      await axios.delete(appConfig.baseURL + "api/delete_imports", config);
      
      // Remove deleted rows from the table
      const newImports = this.state.imports.filter(row => !this.state.selectedRows.includes(row));

      this.setState({
        imports: newImports,
        selectedRows: [],
        clearSelectedRows: true,
      });
      
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({loading: false});
    }
  }

  rowClickedHandler(rowData) {
    switch (rowData[2]) {
      case IMPORT_STATES.done:
        this.setState({
          selectedImportId: rowData[0],
          selectedImportTitle: rowData[1],
          message: "",
        });
        break;
      case IMPORT_STATES.processing_error:
        this.setState({
          message: `'${rowData[1]}' encountered an error during processing.`
        });
        break;
      case IMPORT_STATES.pending:
        this.setState({
          message: `'${rowData[1]}' is pending processing.`,
        });
        break;
      default:
        this.setState({
          message: `'${rowData[1]}' has not finished processing.`
        });
        break;
    }
  }
  
  render() {
    return (
      <div className="CreditUnion text-center container md:mx-auto">
        <Brand/>
        <DashboardStatistics
          imports={this.state.imports}/>

        <SectionHeading>Upload History</SectionHeading>
        <div className="container md:mx-auto px-4">
            <div>
              <PMGDataTable
                columns={this.columns}
                data={this.state.imports}
                loading={this.state.loading}
                selectableRows={true}
                clearSelectedRows={this.state.clearSelectedRows}
                onSelectedRowsChange={this.onSelectedRowsChange.bind(this)}
                selectableRowDisabledHandler={this.selectableRowDisabledHandler.bind(this)}
                onRowClicked={this.rowClickedHandler.bind(this)}
                footerComponent={
                  <div className="flex items-left">
                    <Button 
                      onClick={this.deleteUploadsHandler.bind(this)}>
                      <FontAwesomeIcon icon="trash-can"/> Delete upload(s)
                    </Button>
                    <InfoButton 
                      className="-top-3" 
                      onClick={this.deleteUploadsInfoHandler.bind(this)}/>
                  </div>
                }/>
            </div>
        </div>

        <ModalDialog
          title="Delete uploads?"
          open={this.state.openConfirmDialog}
          positiveButtonText="Yes"
          negativeButtonText="No"
          onPositiveButton={this.deleteUploads.bind(this)}
          onNegativeButton={() => this.setState({openConfirmDialog: false})}
        >
          Are you sure you want to delete the selected uploads?
        </ModalDialog>

        <ModalDialog
          title="Deleting uploads"
          open={this.state.openDeleteUploadsInfo}
          positiveButtonText="Ok"
          onClose={() => this.setState({openDeleteUploadsInfo: false})}
          onPositiveButton={() => this.setState({openDeleteUploadsInfo: false})}
          positiveButtonClassName="ok-btn"
        >
          You can only delete uploads from the last {DELETE_THRESHOLD} days and you cannot delete
          uploads that are still processing.
        </ModalDialog>

        <SectionHeading>Results</SectionHeading>
        <div className="mb-6">
          {this.state.message ?
          <span className="error-text">{this.state.message}</span>
          : 
            this.state.selectedImportId ?
            <div className="mb-6">
              <CUDataTable import_title={this.state.selectedImportTitle} import_id={this.state.selectedImportId}/>
            </div>
            :
            <>No import selected. Select an import to view its results.</>
          }
        </div>
      </div>
    );
  }
}

export default Dashboard;
