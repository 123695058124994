import React from "react";
import { ToastContainer } from "react-toastify";
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import Layout from "components/Layout";
import Homepage from "components/Login/Homepage";
import CreditUnion from "pages/CreditUnion";
import Input from "components/Input";
import Dashboard from "components/Dashboard";
import Superuser from "pages/Superuser";
import CreateCreditUnion from "components/Superuser/CreateCreditUnion";
import ManageCreditUnions from "components/Superuser/ManageCreditUnions";
import ManageImports from "components/Superuser/ManageImports";
import NotFound from "pages/NotFound";
import Iframe from "pages/Iframe";
import "./style/App.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { v4 as uuidv4 } from "uuid";

import { InternalReferrerProvider } from "components/Layout";

function attachSentrySid(event) {
  let found = false;
  document.cookie.split(';').forEach(function(el) {
    let [key,value] = el.split('=');
    if (key.trim() === 'sentry_session_id') {
      Sentry.setTag('sid', value);
      found = true;
      return;
    }
  });
  if (!found) Sentry.setTag('sid', null); //not found so clear any old one
  return event;
};

function attachDeviceInfo(event) {
  let sentryDeviceID = localStorage.getItem('sentry_device_id');
  if (!sentryDeviceID) {
    sentryDeviceID = uuidv4().toString();
    localStorage.setItem('sentry_device_id', sentryDeviceID);
  }
  Sentry.setTag('device.uuid', sentryDeviceID)
  return event;
};

if (process.env.REACT_APP_NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://8be49ea122414618abc4d553a7b96d23@o4504828430450688.ingest.sentry.io/4504828451094528",
    environment: "release",
    beforeSend: (event) => attachDeviceInfo(attachSentrySid(event)),
    beforeSendTransaction:(event) => attachDeviceInfo(attachSentrySid(event)),
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
        new Sentry.Replay({
          blockAllMedia: false,
          maskAllText: false,
          maskAllInputs: false,
        }),
    ],
    tracesSampleRate: 1.0,
  });
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  const currentPage = useLocation().pathname;

  console.log("Page: " + currentPage);

  return (
      <div className={"App flex flex-col justify-between " + (!currentPage || currentPage === "" || currentPage === "/" ? "HomeBG" : "")}>
        <InternalReferrerProvider>
          <SentryRoutes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Homepage />} />

              <Route path="/credit-union" element={<CreditUnion />}>
                <Route path="input" element={<Input />} />
                <Route path="input/:import_id" element={<Input />} />
                <Route path="dashboard" element={<Dashboard />} />
              </Route>

              <Route path="/superuser" element={<Superuser />}>
                <Route path="credit-union/create" element={<CreateCreditUnion />} />
                <Route path="credit-union/manage" element={<ManageCreditUnions />} />
                <Route path="imports/manage" element={<ManageImports />} />
              </Route>
              
              <Route path="*" element={<NotFound />} />
            </Route>
            
            <Route path="/doxim/profile/:doxim_key" element={<Iframe />}/>

            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/about" element={<About />} /> */}
            
          </SentryRoutes>
        </InternalReferrerProvider>

        {/* Container for rendering toasts from react-toastify, there should only be one per app */}
        <ToastContainer position="top-center" theme="colored"/>
      </div>
  );
}

export default App;
