import { useState, useRef } from "react";
import appConfig from "Config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import Button from "components/Buttons/Button";

library.add(faThumbsUp, faThumbsDown);

export default function FeedbackButtons({ widget, uid, doximKey, buttonContainerClassName }) {
  const popupRef = useRef(null);
  const [voted, setVoted] = useState(0);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [correctness, setCorrectness] = useState(false);
  const [clarity, setClarity] = useState(false);

  function giveFeedback(flag = 0, clarity, correctness) {
    const call_params = {
      widget,
      flag,
      clarity,
      correctness,
    };

    axios
      .post(
        appConfig.baseURL +
          "api/iframe/feedback/" +
          doximKey +
          "/" +
          uid,
        call_params
      )
      .then(() => {
        setVoted(0);
        setShowFeedbackForm(false);
      })
      .catch((errors) => {
        console.log(errors);
      });
  }

  function onBlur(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setShowFeedbackForm(false);
    }
  }

  function handleVote(event, flag) {
    event.stopPropagation();
    setVoted(flag);
    setShowFeedbackForm(true);
    setClarity(false);
    setCorrectness(false);
  
    // Allows closing the component by clicking outside of it
    setTimeout(() => {
      if (popupRef.current) {
        popupRef.current.focus();
      }
    }, 0);
  }

  function handleSubmit() {
    giveFeedback(voted, clarity, correctness);
  }

  return (
    <>
      <div className={`feedback ${buttonContainerClassName || ""}`}>
        <div
          className="positive active"
          onClick={(event) => handleVote(event, 1)}
        >
          <FontAwesomeIcon icon={faThumbsUp} size="sm" className="text-[#30B449] hover:text-[#2A9E3F]"/>
        </div>
        <div
          className="negative active"
          onClick={(event) => handleVote(event, 2)}
        >
          <FontAwesomeIcon icon={faThumbsDown} size="sm" className="text-[#EE2923] hover:text-[#BF211E]"/>
        </div>
      </div>
      {showFeedbackForm && (
        <div>
          <div
            ref={popupRef}
            className="absolute top-1 right-1 z-[1000] outline-none"
            onBlur={(event) => onBlur(event)}
            tabIndex="0"
          >
            <div className="relative rounded-md w-[280px] h-fit p-[15px] !bg-white border-[1px] border-black/10 shadow-md">
              <div className="flex flex-col items-start gap-[10px]">
                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="correctnessCheckbox"
                    onChange={() => setCorrectness(!correctness)}
                  />
                  <label htmlFor="correctnessCheckbox" className="font-normal !text-black text-left ml-[7px] text-[13.5px] leading-[1.2]" >
                    {voted === 1 ? 
                      "This information seems very accurate" : 
                      "I think I've encountered incorrect information"
                    }
                  </label>
                </div>

                <div className="flex items-start">
                  <input
                    type="checkbox"
                    id="clarityCheckbox"
                    onChange={() => setClarity(!clarity)}
                  />
                  <label htmlFor="clarityCheckbox" className="font-normal !text-black text-left ml-[7px] text-[13.5px] leading-[1.2]">
                    {voted === 1 ? 
                      "This information was helpful" : 
                      "I'm not sure how to take advantage of this information"
                    }
                  </label>
                </div>

                <div className="flex justify-end w-full">
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit Feedback
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

}