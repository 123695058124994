import React from "react";

let idCounter = 0;

function Input({labelText, placeholder, value, type = "text", onChange, form, autoComplete, buttonComponent}) {
  idCounter++;
  
  function handleOnChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <div className="flex flex-col flex-1">
      <label className="mr-auto" htmlFor={`input-${idCounter}`}>
          {labelText}
      </label>
      <div className="flex gap-1">
        <input 
          {...form?.register(form?.name, form?.options)}
          className="w-full" 
          autoComplete={autoComplete ?? "on"}
          id={`input-${idCounter}`}
          type={type}
          placeholder={placeholder ?? labelText}
          value={value}
          onChange={handleOnChange}/>
        {buttonComponent}
      </div>
      <div className="max-w-fit error-text">{form?.error?.message}</div>
    </div>
  );
}

export default Input;