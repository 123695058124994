import PercentageChart from "components/Charts/PercentageChart";

function BooleanPercentageChart({ title, data }) {
  return (
    <PercentageChart
      title={title}
      data={data}
      groupMode="stacked"
      keys={["No", "Yes"]}
      colors={{
        "Yes": "#3A82D4",
        "No": "#43B5E3",
      }}/>
  );
}

export default BooleanPercentageChart;
