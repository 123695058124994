import Button from "components/Buttons/Button";

function IframeInfoButton({ onClick, tooltip, tooltipPosition }) {
  return (
    <Button
      className="button-override"
      aria-label="info button"
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      onClick={onClick}>
      <img className="min-w-[18px] min-h-[18px] ml-[0.25rem]" src="/info-icon.png" alt="info button"/>
    </Button>
  );
}

export default IframeInfoButton;