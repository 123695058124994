import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "style/ModalDialog.css";

/*
  Dialog component has been adapted from: https://tailwindui.com/components/application-ui/overlays/modals
  Documentation for the dialog component: https://headlessui.dev/react/dialog
  
  Default colours for buttons can be overridden by using the positiveButtonClassName, and negativeButtonClassName props.
*/

function ModalDialog({
  children,
  title,
  open, 
  onClose, 
  onPositiveButton,
  positiveButtonClassName,
  positiveButtonText,
  positiveButtonDisabled,
  onNegativeButton,
  negativeButtonClassName,
  negativeButtonText,
  formId,
  titleClassName,
  contentClassName,
  size = "lg", // Sizes are defined as classes in styles/ModalDialog.css
}) {
  // Must provide at least one of these props
  if (!onClose && !onNegativeButton) {
    throw new Error("You must provide an 'onClose' or 'onNegativeButton' prop so that the dialog can be closed.");
  }
  
  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-10" 
        initialFocus={cancelButtonRef}
        onClose={onClose ?? onNegativeButton}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* Background for dialog */}
          <div data-testid="modal-dialog-background" className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 size-${size} sm:w-full`}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-lg">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" >
                    <Dialog.Title as="h3" className={`${titleClassName} text-lg leading-6 font-medium text-gray-900`}>
                      {title}
                    </Dialog.Title>

                    {/* Content for the dialog */}
                    <div className={`${contentClassName} mt-2 text-sm text-gray-500`}>
                      {children}
                    </div>
                  </div>
                </div>

                {/* Buttons for the dialog */}
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-lg">
                  {positiveButtonText && 
                    <button
                      form={formId}
                      type="button"
                      className={`
                        ${positiveButtonClassName} 
                        ${positiveButtonDisabled && "disabled-button"}
                        bg-red-600 
                        hover:bg-red-700
                        focus:ring-red-500 
                        focus:outline-none 
                        focus:ring-2 
                        focus:ring-offset-2 
                        w-full 
                        inline-flex 
                        justify-center 
                        rounded-md 
                        border 
                        border-transparent 
                        shadow-sm 
                        px-4 
                        py-2 
                        text-base 
                        font-medium 
                        text-white 
                        sm:ml-3 
                        sm:w-auto 
                        sm:text-sm`}
                      onClick={() => onPositiveButton()}
                      disabled={positiveButtonDisabled}>
                      {positiveButtonText}
                    </button>
                  }
                  {negativeButtonText &&
                    <button
                      type="button"
                      className={`${negativeButtonClassName}  border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-indigo-500 mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                      onClick={() => onNegativeButton()}
                      ref={cancelButtonRef}
                    >
                      {negativeButtonText}
                    </button>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )  
}

export default ModalDialog;
