import React from "react";

function Error({ children }) {
  return (
    <h5 className="error-text">
      {children}
    </h5>
  );
}

export default Error;