import { ThreeDots } from "react-loader-spinner";

function SectionLoader({ loading }) {
  return (
    <ThreeDots
      width="100"
      height="100"
      color={(localStorage.getItem("user")) ? localStorage.getItem("user").color : "#0D2539"}
      visible={loading}
      ariaLabel="Loading"
    />
  );
}

export default SectionLoader;