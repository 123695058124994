import PropTypes from "prop-types";

function Chevron({ direction = "down", className = "" }) {
  return (
    <img className={className} src={`${direction === "down" ? "/chevron-down.png" : "/chevron-up.png"}`} alt="up/down arrow" />
  );
}

Chevron.propTypes = {
  direction: PropTypes.oneOf(["up", "down"]).isRequired,
  className: PropTypes.string
};

export default Chevron;