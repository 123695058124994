import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header/Header";
import { createContext, useState, useContext, useEffect } from "react";

const internalReferrerContext = createContext();

// context provider to track the referrer as a replacement for document.referrer since it's generally unavailable in modern browsers,
// only tracks internal navigation
export function InternalReferrerProvider({ children }) {
  const [referrer, setInternalReferrer] = useState("");
  return (
    <internalReferrerContext.Provider value={{ referrer, setInternalReferrer }}>
      {children}
    </internalReferrerContext.Provider>
  );
}

export const useInternalReferrer = () => useContext(internalReferrerContext);

//higher-order component to pass the referrer to a class component
export const withInternalReferrer = WrappedComponent => props => {
  const referrer = useInternalReferrer()?.referrer;
  return (<WrappedComponent {...props} {...{referrer}}/>);
};

export default function Layout() {
  const { internalReferrer, setInternalReferrer } = useInternalReferrer();
  const location = useLocation();

  useEffect(() => setInternalReferrer(location.pathname), [location]);

  return (
    <>
      <Header />

      <div className="flex-grow">
        <Outlet />
      </div>

      <Footer year={new Date().getFullYear()} />
    </>
  );
}