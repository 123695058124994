import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Select from "react-select";
import SelectInputOverride from "components/UserInput/SelectInputOverride";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/Buttons/Button";

library.add(faTrashCan);

const OPTIONS = [
  {
    category: { value: "trajectory", label: "Trajectory" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: "straight", label: "Straight" },
      { value: "upward", label: "Upward" },
      { value: "downward", label: "Downward" },
      { value: "N/A", label: "N/A" },
    ]
  },
  {
    category: { value: "has_homeownership", label: "Home ownership" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [ 
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "has_advisor", label: "Advisor" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "has_registered_accounts", label: "Registered accounts" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "marital_status", label: "Married" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "has_children", label: "Children" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "has_financial_plan", label: "Financial plan" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "auto_deposit", label: "Auto deposit" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "auto_withdrawal", label: "Auto withdrawal" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "mortgage_count", label: "Mortage" },
    operators: [
      { value: "$eq", label: "equals" },
    ],
    values: [
      { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
  },
  {
    category: { value: "hh_income_value", label: "HH Income" },
    operators: [
      { value: "$eq", label: "equals" },
      { value: "$gt", label: "greater than" },
      { value: "$gte", label: "greater than or equal" },
      { value: "$lt", label: "less than" },
      { value: "$lte", label: "less than or equal" },
    ],
    textInput: true,
  },
  {
    category: { value: "hh_assets_value", label: "HH Assets" },
    operators: [
      { value: "$eq", label: "equals" },
      { value: "$gt", label: "greater than" },
      { value: "$gte", label: "greater than or equal" },
      { value: "$lt", label: "less than" },
      { value: "$lte", label: "less than or equal" },
    ],
    textInput: true,
  }
];

// Override the Input component for react-select to pass in a test ID. 
// Adding a test ID allows reliably testing if the correct value input 
// has been rendered (possible value inputs are a textbox and dropdown).
const SelectInputOverrideWithTestId = (props) => (
  <SelectInputOverride {...props} data-testid="filter-value-dropdown-input"/>
);

function ReportFilter({ index, field, control, remove }) {
  const { register, setValue, getValues } = useFormContext();

  const [operatorOptions, setOperatorOptions] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);

  /* Update operator and value inputs on re-render */
  const category = useWatch({ control, name: `filters[${index}].category` }); // Get category anytime the value changes so it can be used to populate the operator and value fields
  
  useEffect(() => {
    const optionsIndex = OPTIONS.findIndex(option => option.category.value === category.value); // Get index for the option that corresponds to the category
    
    const operatorsForCategory = OPTIONS[optionsIndex]?.operators ?? [];
    const valuesForCategory = OPTIONS[optionsIndex]?.values ?? [];
    
    // Set default values for operator and value dropdowns when a filter is added
    if (!getValues(`filters[${index}].operator`)) {
      setValue(`filters[${index}].operator`, operatorsForCategory[0]);
    }
    if (!getValues(`filters[${index}].value`)) {
      // Use the dropdown list options if there are any, if there is no options this input is a number
      setValue(`filters[${index}].value`, valuesForCategory ? valuesForCategory[0] : 0);
    }

    // Update the category and value dropdowns with options for the currently selected category
    setOperatorOptions(operatorsForCategory);
    setValueOptions(valuesForCategory);
  }, [category, index, getValues, setValue]);
  
  function onCategoryChange(category) {
    const selectedCategoryOptionsIndex = OPTIONS.findIndex(option => option.category.value === category.value);

    // Set default values for operator
    setValue(`filters[${index}].operator`, OPTIONS[selectedCategoryOptionsIndex]?.operators[0]);
    // Use the dropdown list options if there are any, if there is no options this input is a number
    setValue(`filters[${index}].value`, OPTIONS[selectedCategoryOptionsIndex]?.values ? OPTIONS[selectedCategoryOptionsIndex]?.values[0] : 0);
    
    setValue(`filters[${index}].category`, category);
  }

  return (
    // Key must be item.id
    <div key={field.id} className="flex gap-1 items-end" data-testid="report-filter">
      <div className="w-1/2">
        <label htmlFor={`filters[${index}].category`}>Category</label>
        <Controller
          name={`filters[${index}].category`}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              inputId={`filters[${index}].category`}
              components={{
                Input: SelectInputOverride
              }}
              options={OPTIONS.map(option => option.category)}
              onChange={onCategoryChange}/>
          )}/>
      </div>
      <div className="w-[30%]">
        <label htmlFor={`filters[${index}].operator`}>Operator</label>
        <Controller
          control={control}
          name={`filters[${index}].operator`}
          render={({ field }) => (
            <Select
              {...field}
              inputId={`filters[${index}].operator`}
              components={{
                Input: SelectInputOverride
              }}
              options={operatorOptions}/>
          )}/>
      </div>
      <div className="w-[30%]">
        <label htmlFor={`filters[${index}].value`}>Value</label>
        {category.value === "hh_assets_value" || category.value === "hh_income_value" ?
          <input 
            data-testid="filter-value-numeric-input"
            id={`filters[${index}].value`}
            name={`filters[${index}].value`}
            {...register(`filters[${index}].value`)}
            className="w-full h-[38px] text-[#333333] border-[#CCCCCC] rounded-md"
            defaultValue={field.value}
            type="number"
            min={0}/>
        :
          <Controller
            control={control}
            name={`filters[${index}].value`}
            render={({ field }) => (
              <Select
                {...field}
                inputId={`filters[${index}].value`}
                components={{
                    Input: SelectInputOverrideWithTestId
                  }}
                options={valueOptions}/>
              )}/>
          }
      </div>
      <div>
        <Button onClick={() => remove(index)} data-testid="remove-filter-button">
          <FontAwesomeIcon icon={faTrashCan}/>
        </Button>
      </div>
    </div>
  );
}

export default ReportFilter;